import { convertScss, ScssObject, ScssProp } from 'ui/scss';

export interface VerticalStackProps
  extends React.ComponentPropsWithRef<'div'>,
    ScssProp {}

export function VerticalStack({ scss, ...props }: VerticalStackProps) {
  return (
    <div className={convertScss({ ...stackStyles, ...scss })} {...props} />
  );
}

const stackStyles: ScssObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  width: '100%',
};

export function VerticalStackContainer({ scss, ...props }: VerticalStackProps) {
  return (
    <div
      className={convertScss({ ...stackStyles, ...containerStyles, ...scss })}
      {...props}
    />
  );
}

const containerStyles: ScssObject = {
  maxWidth: 'container',
  mx: 'auto',
};
