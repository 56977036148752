import { useMediaQuery } from '@react-hook/media-query';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom-6';
import { DelayedRender } from 'ui';
import { SpinnerIcon } from 'ui/icons';
import { convertScss, cx, ScssProp } from 'ui/scss';

interface ButtonOwnProps {
  color?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  hotkey?: string;
  variant?: Variant;
  loading?: boolean;
}
type Variant = 'solid' | 'outline' | 'text' | 'icon';

interface ButtonProps
  extends ButtonOwnProps,
    ScssProp,
    React.ComponentPropsWithoutRef<'button'> {}

export function Button({
  children,
  leftIcon,
  rightIcon,
  hotkey,
  variant,
  color,
  loading,
  scss,
  ...props
}: ButtonProps) {
  const hasHover = useMediaQuery('(hover: hover)');
  const showIcon = (!hotkey || !hasHover) && !loading;
  const classes = useMemo(
    () => getClassNames({ variant, color, loading }),
    [variant, color, loading]
  );
  const showLoading = loading && variant !== 'text' && variant !== 'icon';

  return (
    <button className={cx(classes, convertScss(scss))} {...props}>
      {leftIcon && showIcon && leftIcon}
      <span className="label">{children}</span>
      {rightIcon && showIcon && rightIcon}
      {hotkey && hasHover && !loading && <Hotkey hotkey={hotkey} />}
      {showLoading && <Loading />}
      <div className="fill" />
    </button>
  );
}

interface LinkButtonProps
  extends ButtonOwnProps,
    ScssProp,
    React.ComponentPropsWithoutRef<typeof Link> {}

export function LinkButton({
  children,
  leftIcon,
  rightIcon,
  variant,
  hotkey,
  color,
  loading,
  scss,
  ...props
}: LinkButtonProps) {
  const hasHover = useMediaQuery('(hover: hover)');
  const showIcon = (!hotkey || !hasHover) && !loading;
  const classes = useMemo(
    () => getClassNames({ variant, color, loading }),
    [variant, color, loading]
  );
  return (
    <Link className={cx(classes, convertScss(scss))} {...props}>
      {leftIcon && showIcon && leftIcon}
      <span className="label">{children}</span>
      {rightIcon && showIcon && rightIcon}
      {hotkey && hasHover && <Hotkey hotkey={hotkey} />}
      <div className="fill" />
    </Link>
  );
}

interface UnstyledButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    ScssProp {}

export function UnstyledButton({ scss, ...props }: UnstyledButtonProps) {
  return <button className={cx(clearStyles, convertScss(scss))} {...props} />;
}

const clearStyles = convertScss({
  // Clear unwanted browser styles
  textDecoration: 'none',
  border: 'none',
  outline: 'none',
  appearance: 'none',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  fontFamily: 'body',
});

function getClassNames({
  variant = 'solid',
  color,
  loading,
}: {
  variant?: Variant;
  color?: string;
  loading?: boolean;
}) {
  if (variant === 'solid') {
    return cx(
      baseStatic,
      baseDynamic({ color, loading }),
      solidStatic,
      solidDynamic({ color })
    );
  }

  if (variant === 'outline') {
    return cx(
      baseStatic,
      baseDynamic({ color, loading }),
      outlineStatic,
      outlineDynamic({ color })
    );
  }

  if (variant === 'text') {
    return cx(baseStatic, baseDynamic({ color, loading }));
  }

  if (variant === 'icon') {
    return cx(
      baseStatic,
      baseDynamic({ color, loading }),
      iconStatic,
      iconDynamic({ color })
    );
  }

  throw new Error('Invalid button variant');
}

// Separate static and dynamic styles for better perf
const baseStatic = convertScss({
  // Clear unwanted browser styles
  textDecoration: 'none',
  border: 'none',
  outline: 'none',
  userSelect: 'none',
  appearance: 'none',
  WebkitTapHighlightColor: 'rgba(255,255,255,0.4)',
  // Typography
  fontWeight: 'bold',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  fontFamily: 'body',
  // Positioning
  display: 'inline-flex',
  width: 'auto',
  justifyContent: 'center',
  position: 'relative',
  alignItems: 'center',
  overflow: 'visible',
  verticalAlign: 'center',
  // Styling
  borderRadius: 'small',
  px: 16,
  height: 48,
  backgroundColor: 'transparent',
  // Transitions
  willChange: 'opacity',
  '@media (hover: hover)': {
    ':hover': {
      cursor: 'pointer',
      '.fill': {
        opacity: 0.08,
      },
    },
  },
  ':focus': {
    '.fill': {
      transitionDuration: '75ms',
      opacity: 0.12,
    },
  },
  ':active': {
    outline: 'none',
    '.fill': {
      transitionDuration: '250ms',
      opacity: 0.24,
    },
  },
  ':not(:active) .fill': {
    transition: 'opacity 150ms linear',
  },
  ':disabled': {
    cursor: 'default',
    pointerEvents: 'none',
    color: 'rgba(0, 0, 0, 0.38)',
  },
  // Fill div used for background color and transitions (google calls it ripple)
  '.fill': {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    opacity: 0,
    pointerEvents: 'none',
    borderRadius: 'small',
  },
  // Add margins to icon if present
  svg: {
    display: 'inline-block',
    ml: 0,
    mr: 8,
  },
  '.label + svg': {
    ml: 8,
    mr: 0,
  },
  // Keyboard shortcut preview
  '.hotkeys': {
    position: 'absolute',
    display: 'flex',
    right: 10,
  },
  '.hotkeys .hotkey': {
    borderRadius: 'small',
    px: 6,
    py: 2,
    textTransform: 'uppercase',
    ml: 2,
    opacity: 0.85,
  },
});

function baseDynamic({
  color = 'blue',
  loading,
}: {
  color?: string;
  loading?: boolean;
}) {
  return convertScss({
    cursor: loading ? 'default' : null,
    pointerEvents: loading ? 'none' : null,

    ':not(:disabled)': {
      color,
    },
    '.fill': {
      bg: color,
      transitionDuration: loading ? '250ms' : null,
      opacity: loading ? '0.24 !important' : null,
    },
  });
}

// Adds styles for solid button
const solidStatic = convertScss({
  boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)`,
  transition: 'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)',
  ':not(:disabled)': {
    color: 'white',
  },
  '@media (hover: hover)': {
    ':hover, :focus': {
      boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
    },
  },
  ':active': {
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
  },
  ':disabled': {
    color: 'rgba(0, 0, 0, 0.38)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    boxShadow: `0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)`,
  },
  '.fill': {
    backgroundColor: 'white',
  },
  '.hotkey': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
});

function solidDynamic(props: { color?: string }) {
  const color = props.color || 'blue';
  return convertScss({
    ':not(:disabled)': {
      backgroundColor: color,
    },
  });
}

// Make an outlined button
const outlineStatic = convertScss({
  borderStyle: 'solid',
  borderWidth: 1,
  px: 15,
  '.hotkeys .hotkey': {
    px: 2,
  },
});

function outlineDynamic(props: { color?: string }) {
  const color = props.color || 'blue';
  return convertScss({
    ':not(:disabled)': {
      color,
    },
  });
}

// Make an icon button
const iconStatic = convertScss({
  px: 0,
  py: 0,
  height: 32,
  // width: 48,
  ':not(:disabled)': {
    color: 'black',
  },
  svg: {
    display: 'block',
    mx: 0,
  },
});

function iconDynamic(props: { color?: string }) {
  const color = props.color || 'buttonGray';
  return convertScss({
    ':not(:disabled)': {
      backgroundColor: color,
    },
  });
}

function getKeyCharacter(key: string) {
  switch (key) {
    case 'enter':
      return '⏎';
    case 'command':
      return '⌘';
    default:
      return key;
  }
}

const Hotkey = memo(function Hotkey({ hotkey }: { hotkey: string }) {
  return (
    <div className="hotkeys">
      {hotkey.split('+').map((key) => (
        <div className="hotkey" key={key}>
          {getKeyCharacter(key)}
        </div>
      ))}
    </div>
  );
});

const Loading = memo(function Loading() {
  const containerStyles = convertScss({
    position: 'absolute',
    display: 'flex',
    right: 10,
  });
  return (
    <DelayedRender delay={1000}>
      <div className={containerStyles}>
        <SpinnerIcon size={24} />
      </div>
    </DelayedRender>
  );
});
