import { PageLogo } from './PageLogo';
import { convertScss } from 'ui/scss';

interface NavbarProps {
  children: React.ReactNode;
  hideLogo?: boolean;
  nav: React.ReactNode;
}

export function NavbarPage({ children, hideLogo, nav }: NavbarProps) {
  return (
    <div className={styles}>
      {nav}
      {children}
      {!hideLogo && <PageLogo />}
    </div>
  );
}

const styles = convertScss({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  // Hack to fix mobile Safari 100vh bug
  // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit
  maxHeight: '-webkit-fill-available',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  label: 'NavbarPage',
});
