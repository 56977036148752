import React from 'react';
import { SVG } from 'ui';
import 'ui/spinner.css';

type SVGProps = React.ComponentProps<typeof SVG>;
type IconProps = { color?: string; size?: number } & SVGProps;

// Helper component to simplify the code for each individual icon
export function Icon({
  children,
  color = 'currentcolor',
  size = 32,
  scss,
  ...props
}: IconProps) {
  const viewBox = `0 0 32 32`;
  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
      scss={{ stroke: color, ...scss }}
      {...props}
    >
      {children}
    </SVG>
  );
}

export function ArrowLeftIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M13.3334 22.6667L6.66669 16L13.3334 9.33332M26.6667 16L7.00003 16" />
    </Icon>
  );
}

export function ArrowRightIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M18.6666 9.33334L25.3333 16L18.6666 22.6667M5.33331 16L25 16" />
    </Icon>
  );
}

export function CheckIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M8.83338 18L12.6667 21.8333L24.5 10" />
    </Icon>
  );
}

export function CheckCircleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
      <path d="M11 17.4085L13.6915 20L21 13" />
    </Icon>
  );
}

export function ChevronDownIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M12 14L15.835 17.835L19.67 14" />
    </Icon>
  );
}

export function ChevronRightIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M14 20L17.835 16.165L14 12.33" />
    </Icon>
  );
}

export function CopyIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M19.5 22V25.5H6.5V12.5H10M12.5 6.5H25.5V19.5H12.5V6.5Z" />
    </Icon>
  );
}

export function CrossCircleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M20 20L16 16M16 16L12 12M16 16L12 20M16 16L20 12M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
    </Icon>
  );
}

export function EyeIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M15.8237 21.5C11.1437 21.5 7.01953 19.119 4.59696 15.5C7.01953 11.881 11.1437 9.5 15.8237 9.5C20.5037 9.5 24.6279 11.881 27.0504 15.5C24.6279 19.119 20.5037 21.5 15.8237 21.5Z" />
      <circle cx="16" cy="15" r="3.5" />
    </Icon>
  );
}

export function EyeClosedIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M15.8237 21.5C11.1437 21.5 7.01953 19.119 4.59696 15.5C7.01953 11.881 11.1437 9.5 15.8237 9.5C20.5037 9.5 24.6279 11.881 27.0504 15.5C24.6279 19.119 20.5037 21.5 15.8237 21.5Z" />
      <path d="M7 25L25 7" />
    </Icon>
  );
}

export function GraduationHatIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M24.5 14.5V20L16.3036 24L7.5 20V14.5M22 15.5V26.5M2.5 12L15.5179 6L29.5 12L16.4821 18L2.5 12Z" />
    </Icon>
  );
}

export function IdCardIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M17.5 7.5V5.5H15.5V7.5M17.5 7.5V9.5H15.5V7.5M17.5 7.5H28.5V23.5H3.5V7.5H15.5M16 16.5H25M16 19.5H22M16 13.5H25M7.5 11.5H12.5V17.5H7.5V11.5Z" />
    </Icon>
  );
}

export function ImportIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M13.5 13L13.5 6.5L25.5 6.5L25.5 25.5L13.5 25.5L13.5 18M4 15.5L20.5 15.5M17 19.5L21 15.5L17 11.5" />
    </Icon>
  );
}

export function InfoCircleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M14 14H16V20M16 20H13.5M16 20H18.5M16 10V12M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
    </Icon>
  );
}

export function LinkedInIcon({ scss, ...props }: IconProps) {
  return (
    <Icon scss={{ ...scss, stroke: 'none' }} {...props}>
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
        fill="#2A3FFB"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.89501 8.65855C9.86677 7.37671 8.95012 6.40039 7.46163 6.40039C5.97313 6.40039 5 7.37671 5 8.65855C5 9.91383 5.94437 10.9183 7.40514 10.9183H7.43295C8.95012 10.9183 9.89501 9.91383 9.89501 8.65855ZM9.60817 12.7026H5.25703V25.7759H9.60817V12.7026ZM21.2904 12.3965C24.1537 12.3965 26.3002 14.2654 26.3002 18.281L26.3 25.7768H21.9491V18.7826C21.9491 17.0258 21.3194 15.827 19.7443 15.827C18.5422 15.827 17.8262 16.6351 17.5117 17.4157C17.3967 17.6955 17.3684 18.0852 17.3684 18.4759V25.7772H13.0168C13.0168 25.7772 13.0741 13.9306 13.0168 12.7038H17.3684V14.5556C17.9459 13.6654 18.9802 12.3965 21.2904 12.3965Z"
        fill="white"
      />
    </Icon>
  );
}

export function MailIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M4.5 8.5V8H4V8.5H4.5ZM27.5 8.5H28V8H27.5V8.5ZM27.5 23.5V24H28V23.5H27.5ZM4.5 23.5H4V24H4.5V23.5ZM16 18.5L15.6877 18.8904L16 19.1403L16.3123 18.8904L16 18.5ZM4.5 9H27.5V8H4.5V9ZM27 8.5V9.30013H28V8.5H27ZM27 9.30013V23.5H28V9.30013H27ZM27.5 23H4.5V24H27.5V23ZM5 23.5V9.30013H4V23.5H5ZM5 9.30013V8.5H4V9.30013H5ZM4.18766 9.69057L15.6877 18.8904L16.3123 18.1096L4.81234 8.9097L4.18766 9.69057ZM16.3123 18.8904L27.8123 9.69057L27.1877 8.9097L15.6877 18.1096L16.3123 18.8904Z" />
    </Icon>
  );
}

export function MinusCircleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M12 16H16H20M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
    </Icon>
  );
}

export function PlusIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M7 16H25M16 25V7" />
    </Icon>
  );
}

export function PlusCircleIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M10 16H16M22 16H16M16 16V10V22M26 16C26 21.5228 21.5228 26 16 26C10.4772 26 6 21.5228 6 16C6 10.4772 10.4772 6 16 6C21.5228 6 26 10.4772 26 16Z" />
    </Icon>
  );
}

export function ResetIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M12.4185 10H16.4185C20.8368 10 24.4185 13.5817 24.4185 18C24.4185 22.4183 20.8368 26 16.4185 26C14.3896 26 12.5371 25.2447 11.1268 24C10.2034 23.185 9.4696 22.1601 9 21M12.4185 10L16.4185 6M12.4185 10L16.4185 14" />
    </Icon>
  );
}

export function SpinnerIcon(props: IconProps) {
  return (
    <Icon className="spinner-icon" viewBox="0 0 50 50" {...props}>
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </Icon>
  );
}

export function SuitcaseIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M19.5 10.5V6.5H12.5V10.5M4.5 15.5V25.5H27.5V15.5M4.5 15.5V10.5H27.5V15.5M4.5 15.5H27.5M21.5 14V17M10.5 14V17" />
    </Icon>
  );
}

export function VeryHappyIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M9.07025 19C10.4535 21.3912 13.0389 23 16 23C18.9611 23 21.5465 21.3912 22.9297 19M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" />
      <path d="M13 14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13C12.5523 13 13 13.4477 13 14Z" />
      <path d="M20 15C20.5523 15 21 14.5523 21 14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14C19 14.5523 19.4477 15 20 15Z" />
    </Icon>
  );
}

export function WandIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M13.5 13.5L27 28M12 10V6M12 18V14M10.5858 10.5858L7.75735 7.75736M13.4142 10.5858L16.2426 7.75736M7.75736 16.2426L10.5858 13.4142M14 12H18M6 12H10" />
    </Icon>
  );
}
