// WARNING:
//
// This is duplicated at app/src/v1/components/preview/all-templates-info.js
//

import { Customization, Customizations, TemplateId } from '@sr/types';

// prettier-ignore
const defaultPresetColors = [ '#000000', '#434343', '#666666', '#999999', '#D9D9D9', '#EFEFEF', '#F3F3F3', '#ffffff', '#041E54', '#0B2F76', '#1244A3', '#1B58CD', '#1F65F5', '#4C88F6', '#7DA6F8', '#ADC5F9', '#290B3B', '#3E1556', '#582078', '#702C99', '#8C36B6', '#AE49EB', '#C363F5', '#D797F7', '#541006', '#781E0D', '#A82C18', '#D13920', '#EB512E', '#ED6C59', '#F19286', '#F4B8B1', '#52350D', '#754C16', '#A16B22', '#CA872E', '#F4AD3D', '#F5B557', '#F7C882', '#FADAAE', '#66621B', '#8D8528', '#C3BB3C', '#F4EB4E', '#FFFA67', '#FFF680', '#FFF7A2', '#FEFBC0', '#2B3C15', '#3E5622', '#587834', '#739B44', '#87B854', '#A3D06E', '#BADB94', '#D2E6BA', ];

const sanJuan: TemplateInfo = {
  description: 'Simple & Professional',
  id: 'san-juan',
  name: 'Sidney',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/san-juan_rw5wlr.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#323336',
    text2: '#707678',
    text3: '#A6AAAD',
    accent1: '#43F398',
    accent2: '#000000',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Monogram background',
    },
    accent2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Monogram text',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
    fontStyle: {
      type: 'select',
      presets: [
        { value: 'standard', label: 'Standard' },
        { value: 'technical', label: 'Technical' },
        { value: 'classic', label: 'Classic' },
      ],
      label: 'Type style',
      isLegacy: true,
    },
    fontSize: {
      type: 'select',
      presets: [
        { value: 'tiny', label: 'Tiny' },
        { value: 'small', label: 'Small' },
        { value: 'normal', label: 'Normal' },
        { value: 'large', label: 'Large' },
      ],
      label: 'Text size',
      isLegacy: true,
    },
    monogramStyle: {
      type: 'select',
      presets: [
        { value: 'rectangle-solid', label: 'Solid rectangle' },
        { value: 'rectangle-outline', label: 'Outline rectangle' },
        { value: 'circle-solid', label: 'Solid circle' },
        { value: 'circle-outline', label: 'Outline circle' },
        { value: 'none', label: 'None' },
      ],
      label: 'Monogram style',
      isLegacy: true,
    },
  },
};

export type SanJuanCustomizations = Required<
  Pick<
    Customization,
    | 'baseSize'
    | 'text1'
    | 'text2'
    | 'text3'
    | 'accent1'
    | 'accent2'
    | 'background'
    | 'fontStyle'
    | 'fontSize'
    | 'monogramStyle'
  >
>;

const pender: TemplateInfo = {
  description: 'Minimal & Modern',
  id: 'pender',
  name: 'Pender',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/pender_tqwmqo.png`,
  supportsProfilePhoto: true,
  defaultCustomizations: {
    text1: '#2A3235',
    text2: '#6D7A7E',
    text3: '#B6BBBC',
    accent1: '#AF38F8',
    accent2: '#fff',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Titles',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'List item background',
    },
    accent2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'List item text',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type PenderCustomizations = Required<
  Pick<
    Customization,
    | 'baseSize'
    | 'text1'
    | 'text2'
    | 'text3'
    | 'accent1'
    | 'accent2'
    | 'background'
  >
>;

const yew: TemplateInfo = {
  description: 'Traditional & Simple',
  id: 'yew',
  name: 'Yew',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/yew_cuoat2.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#000000',
    text2: '#4E4E4E',
    text3: '#757575',
    accent1: '#918C75',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type YewCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

const eton: TemplateInfo = {
  description: 'Minimal & Technical',
  id: 'eton',
  name: 'Eton',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/eton_qtb4gl.png`,
  supportsProfilePhoto: true,
  defaultCustomizations: {
    text1: '#004F71',
    text2: '#004F71',
    text3: '#004F71',
    accent1: '#004F71',
    background: '#EFF6FF',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type EtonCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

const alexander: TemplateInfo = {
  description: 'Traditional & Condensed',
  id: 'alexander',
  name: 'Alexander',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/alexander_nv1oxx.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#000',
    text2: '#626367',
    text3: '#717278',
    accent1: '#717278',
    accent2: '#717278',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Dates',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'List item colors',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type AlexanderCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

const cordova: TemplateInfo = {
  description: 'Bold & Elegant',
  id: 'cordova',
  name: 'Cordova',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/cordova_wddyiv.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#000000',
    text2: '#5D606B',
    text3: '#969CBA',
    accent1: '#FFCDFA',
    accent2: '#FFE9BE',
    accent3: '#CA2B8A',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Titles',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Dates',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Accent color 1',
    },
    accent2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Accent color 2',
    },
    accent3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type CordovaCustomizations = Required<
  Pick<
    Customization,
    | 'baseSize'
    | 'text1'
    | 'text2'
    | 'text3'
    | 'accent1'
    | 'accent2'
    | 'accent3'
    | 'background'
  >
>;

const arbutus: TemplateInfo = {
  description: 'Classic & Clean',
  id: 'arbutus',
  name: 'Arbutus',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/arbutus_ap00dp.png`,
  supportsProfilePhoto: true,
  defaultCustomizations: {
    text1: '#2A2B31',
    text2: '#5C5F71',
    text3: '#868BAA',
    accent1: '#3949AA',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type ArbutusCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

const seymore: TemplateInfo = {
  description: 'Modern & Elegant',
  id: 'seymore',
  name: 'Seymore',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/seymore_qwj8b4.png`,
  supportsProfilePhoto: true,
  defaultCustomizations: {
    text1: '#232E53',
    text2: '#757d94',
    accent1: '#b8adfb',
    accent2: '#C6C4D7',
    background: '#F0F3F5',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    accent2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Separator color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type SeymoreCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'accent1' | 'accent2' | 'background'
  >
>;

const keefer: TemplateInfo = {
  description: 'Technical & Modern',
  id: 'keefer',
  name: 'Keefer',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/keefer_m12zp5.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#323336',
    text2: '#707678',
    text3: '#C1C5C7',
    accent1: '#4F6AF6',
    accent2: '#4F6AF6',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Subtitles',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Monogram color',
    },
    accent2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills color',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type KeeferCustomizations = Required<
  Pick<
    Customization,
    | 'baseSize'
    | 'text1'
    | 'text2'
    | 'text3'
    | 'accent1'
    | 'accent2'
    | 'background'
  >
>;

const georgia: TemplateInfo = {
  description: 'Bold & Eye-catching',
  id: 'georgia',
  name: 'Georgia',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/georgia_nlimi1.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#E36E60',
    text2: '#878787',
    text3: '#878787',
    accent1: '#E36E60',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Titles',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Primary text',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Item subtitles',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Initials',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type GeorgiaCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

const parker: TemplateInfo = {
  description: 'Simple & Modern',
  id: 'parker',
  name: 'Parker',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/parker_ajykei.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#000',
    text2: '#4F5157',
    accent1: '#2A3FFB',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Dates',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Section titles',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type ParkerCustomizations = Required<
  Pick<Customization, 'baseSize' | 'text1' | 'text2' | 'accent1' | 'background'>
>;

const venables: TemplateInfo = {
  description: 'Traditional & Clean',
  id: 'venables',
  name: 'Venables',
  thumbnailUrl: `https://assets.standardresume.co/f_auto,q_auto/v1/app/templates/venables_mdaah6.png`,
  supportsProfilePhoto: false,
  defaultCustomizations: {
    text1: '#000',
    text2: '#4F5157',
    text3: '#E34848',
    accent1: '#E34848',
    background: '#fff',
  },
  customizationInfo: {
    text1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Text',
    },
    text2: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Dates',
    },
    text3: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Skills',
    },
    accent1: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Heading',
    },
    background: {
      type: 'color',
      presets: defaultPresetColors,
      label: 'Background',
    },
  },
};

export type VenablesCustomizations = Required<
  Pick<
    Customization,
    'baseSize' | 'text1' | 'text2' | 'text3' | 'accent1' | 'background'
  >
>;

export const AllTemplatesInfo: Readonly<Record<TemplateId, TemplateInfo>> = {
  'san-juan': sanJuan,
  pender,
  yew,
  eton,
  alexander,
  cordova,
  arbutus,
  seymore,
  keefer,
  georgia,
  parker,
  venables,
};

const partialDefaults: Customizations = {};
for (const [key, value] of Object.entries(AllTemplatesInfo)) {
  partialDefaults[key as TemplateId] = value.defaultCustomizations;
}
export const DEFAULT_CUSTOMIZATIONS: Customizations = partialDefaults;

export type TemplateInfo = Readonly<{
  description: string;
  id: TemplateId;
  name: string;
  thumbnailUrl: string;
  supportsProfilePhoto: boolean;
  defaultCustomizations: Customization;
  customizationInfo: CustomizationsInfo;
}>;

export type CustomizationsInfo = {
  [Property in keyof Customization]: CustomizationInfo;
};

export type CustomizationInfo =
  | {
      type: 'color';
      presets: string[];
      label: string;
    }
  | {
      // Legacy customization info used by san-juan template
      type: 'select';
      presets: { value: string; label: string }[];
      label: string;
      isLegacy: true;
    };

export function getDefaultCustomizations(
  templateId: TemplateId
): Customization {
  return AllTemplatesInfo[templateId].defaultCustomizations;
}

export function getCustomizationsInfo(
  templateId: TemplateId
): CustomizationsInfo {
  return AllTemplatesInfo[templateId].customizationInfo;
}

export function supportsProfilePhoto(id: TemplateId): boolean {
  return AllTemplatesInfo[id].supportsProfilePhoto;
}
