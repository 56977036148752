/* eslint-disable jsx-a11y/heading-has-content, jsx-a11y/alt-text */

import { convertScss, ScssProp } from './scss';

export interface DivProps
  extends React.ComponentPropsWithoutRef<'div'>,
    ScssProp {}

export function Box({ scss, ...props }: DivProps) {
  return <div className={convertScss(scss)} {...props} />;
}

export interface FormProps
  extends React.ComponentPropsWithoutRef<'form'>,
    ScssProp {}

export function Form({ scss, ...props }: FormProps) {
  return <form className={convertScss(scss)} {...props} />;
}

export function Grid({ scss, ...props }: DivProps) {
  return (
    <div className={convertScss({ display: 'grid', ...scss })} {...props} />
  );
}

export interface H1Props
  extends React.ComponentPropsWithoutRef<'h1'>,
    ScssProp {}

export function Heading({ scss, ...props }: H1Props) {
  return (
    <h1
      className={convertScss({
        fontFamily: 'heading',
        fontSize: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        margin: 0,
        ...scss,
      })}
      {...props}
    />
  );
}

export interface ImageProps
  extends React.ComponentPropsWithoutRef<'img'>,
    ScssProp {}

export function Image({ scss, ...props }: ImageProps) {
  return <img className={convertScss(scss)} {...props} />;
}

export interface LabelProps
  extends React.ComponentPropsWithoutRef<'label'>,
    ScssProp {}

export function Label({ scss, ...props }: LabelProps) {
  return (
    <label
      className={convertScss({
        fontFamily: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        display: 'block',
        fontWeight: 'bold',
        mb: 8,
        ...scss,
      })}
      {...props}
    />
  );
}

export interface OlProps
  extends React.ComponentPropsWithoutRef<'ol'>,
    ScssProp {}

export function Ol({ scss, ...props }: OlProps) {
  return <ol className={convertScss(scss)} {...props} />;
}

export interface SpanProps
  extends React.ComponentPropsWithoutRef<'span'>,
    ScssProp {}

export function Span({ scss, ...props }: SpanProps) {
  return <span className={convertScss(scss)} {...props} />;
}

export interface SvgProps
  extends React.ComponentPropsWithoutRef<'svg'>,
    ScssProp {}

export function SVG({ scss, className, ...props }: SvgProps) {
  return (
    <svg className={`${convertScss(scss)} ${className}`.trim()} {...props} />
  );
}
