import { useMemo } from 'react';
import { VerticalStack } from 'ui';
import { convertScss } from 'ui/scss';

export function SplitContent({
  left,
  right,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
}) {
  const styles = useMemo(() => ({ alignItems: 'left' }), []);
  return (
    <>
      <div className={leftStyles}>
        <VerticalStack scss={styles}>{left}</VerticalStack>
      </div>
      <div className={rightStyles}>{right}</div>
    </>
  );
}

const leftStyles = convertScss({
  display: 'grid',
  // This is the weird way to force equal width columns
  // https://twitter.com/wesbos/status/1256229763225657348
  gridTemplateColumns: [
    'minmax(0, 600px)',
    'minmax(0, 600px)',
    'minmax(0, 1fr) minmax(0, 1fr)',
  ],
  gridGap: 24,
  justifyContent: 'center',
  minHeight: [null, null, '100%'],
  width: '100%',
  maxWidth: 'container',
  mx: 'auto',
  label: 'Left',
});

const rightStyles = convertScss({
  position: [null, null, 'absolute'],
  top: [null, null, 0],
  bottom: [null, null, 0],
  right: [null, null, 0],
  left: [null, null, '50%'],
  display: ['block', 'block', 'flex'],
  alignItems: [null, null, 'center'],
  justifyContent: [null, null, 'center'],
  mt: [36, 48, null],
  zIndex: [null, null, -1],
  label: 'Right',
});
