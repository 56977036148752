import * as Sentry from '@sentry/react';
import * as React from 'react';

// This is copied from the Sentry docs
// https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

function FallbackComponent({ fullscreen }: { fullscreen: boolean }) {
  return (
    <div
      style={{
        width: fullscreen ? '100vw' : '100%',
        height: fullscreen ? '100vh' : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'monospace',
      }}
    >
      An error has occurred.
    </div>
  );
}

const dialogOptions = {
  subtitle: 'Sorry for the inconvenience — our team as been notified.',
  subtitle2: 'In the meantime, try refreshing the page or using Google Chrome.',
};

export default function ErrorBoundary({
  children,
  fullscreen,
  location,
}: {
  children: React.ReactNode;
  fullscreen?: boolean;
  location: string;
}) {
  return (
    <Sentry.ErrorBoundary
      dialogOptions={dialogOptions}
      showDialog
      beforeCapture={(scope) => {
        scope.setTag('location', location);
      }}
      onError={handleChunkLoadError}
      fallback={() => {
        return <FallbackComponent fullscreen={fullscreen ? true : false} />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

// A common error is that a Webpack chunk no longer exists. This happens when a
// deploy happens after a user has loaded index.html that stores the filename
// for each chunk, which can change from the deploy. The solution is to reload
// the page, which will update the app to use the new filenames.
// Solution copied from: https://mitchgavan.com/code-splitting-react-safely/
function handleChunkLoadError(error: Error) {
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  // Check if the error was a webpack chunk loading error
  if (error?.message && chunkFailedMessage.test(error.message)) {
    // Check if reloaded recently to avoid infinite reload loop
    if (!getWithExpiry('chunk_failed')) {
      // Give Sentry 1000ms to log the error
      setTimeout(() => {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }, 1000);
    } else {
      // Log special error for when a chunk load error isn't fixed by reloading
      Sentry.captureException(
        new Error(`Confirmed chunk load errror: ${error.message}`)
      );
    }
  }
}

// These set and get functions are used to avoid an infinite reload loop
function setWithExpiry(key: string, value: string, ttl: number) {
  const item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}
