import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { initializeSentry, Sentry } from 'utils/sentry';
import 'url-search-params-polyfill';

// Global CSS to cleanup/standardize
import 'ui/modern-normalize.css';

initializeSentry();

// @ts-ignore
const OldApp = lazy(() => import('v1/components/App'));

// Wrap the app in Sentry's profiler
const ChosenApp = Sentry.withProfiler(ChooseApp);

ReactDOM.render(
  <React.StrictMode>
    <ChosenApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// Choose which app to load based on the URL
function ChooseApp() {
  // All new app paths start with /v2
  const isNewApp = window.location.pathname.indexOf('/v2') === 0;
  const app = isNewApp ? (
    <App />
  ) : (
    <Suspense fallback={null}>
      <OldApp />
    </Suspense>
  );
  return app;
}
