import { Route } from 'react-router-dom-6';
import { useAuth } from 'context/auth-context';

type RouteProps = React.ComponentPropsWithoutRef<typeof Route>;

// A route that redirects unauthenticated user to the login page
export default function PrivateRoute(props: RouteProps) {
  const auth = useAuth();

  // Send unauthenticated users to login page
  if (!auth.isAuthed) {
    // Can't use react rotuer for this because the old app uses a different router
    window.location.pathname = '/login';
    return null;
  }

  return <Route {...props} />;
}
