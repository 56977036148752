import { captureException } from '@sentry/react';
import is from '@sindresorhus/is';
import { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom-6';
import { getUser, updateUserTrackingInfo } from 'api/user';
import { useAuth } from 'context/auth-context';
import { DelayedFullscreenSpinner } from 'ui/DelayedFullscreenSpinner';

// Redirects the user to the correct place based on their auth state
export default function RedirectBasedOnAuth() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (auth.isAuthed) {
      redirectExistingUser(auth.userId, navigate).catch((error) => {
        captureException(error, { tags: { noFeedback: true } });
      });
    }
  }, [auth.userId, auth.isAuthed, navigate]);

  // If the user isn't authenticated, send them to the beginning of setup
  if (!auth.isAuthed) {
    let path = 'new-user';

    // Pass along the templateid search param if present on the current URL
    const templateId = searchParams.get('templateid');
    const hasTemplateId = is.nonEmptyString(templateId);
    if (hasTemplateId) {
      path = `${path}?templateid=${templateId}`;
    }

    return <Navigate to={path} replace={true} />;
  }

  return <DelayedFullscreenSpinner />;
}

export async function redirectExistingUser(
  userId: string,
  navigate: (arg0: string, arg1: { replace: boolean }) => void
) {
  const user = await getUser(userId);

  // Make sure the user exists and has a resume
  if (!user) throw new Error("No doesn't exist");
  if (!user.resumes) throw new Error('User has no resumes');

  if (user.isAnonymous) {
    const resumeId = Object.keys(user.resumes)[0];
    updateUserTrackingInfo(user.id).catch((error) =>
      captureException(error, { tags: { noFeedback: true } })
    );
    navigate(`/v2/setup/${resumeId}/import/choose-method`, { replace: true });
  } else {
    // Already has an account. Send to editor.
    // We can't use a react router link here because this app uses a different
    // instance of react router.
    window.location.pathname = '/resumes';
  }
}
