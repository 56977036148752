function isBot() {
  if (
    // Most search bots
    window.navigator.userAgent.toLowerCase().includes('bot') ||
    // Random crawlers
    window.navigator.userAgent.toLowerCase().includes('crawl') ||
    // Some search bots
    window.navigator.userAgent.toLowerCase().includes('spider') ||
    // Cypress integration tests (also used for Percy screenshots)
    window.navigator.userAgent.toLowerCase().includes('cypress') ||
    // Checkly e2e testing
    window.navigator.userAgent.toLowerCase().includes('checkly') ||
    // Percy visual testing
    window.navigator.userAgent.toLowerCase().includes('percy') ||
    // Cloudflare health checks
    window.navigator.userAgent.toLowerCase().includes('cloudflare') ||
    // Any headless Chrome useragents
    window.navigator.userAgent.toLowerCase().includes('headlesschrome') ||
    // Chrome Lighthouse testing
    window.navigator.userAgent.toLowerCase().includes('lighthouse') ||
    // Google uptime monitoring. Intentionally vague to catch other monitoring
    window.navigator.userAgent.toLowerCase().includes('monitoring')
  ) {
    return true;
  } else {
    return false;
  }
}

export default isBot;
