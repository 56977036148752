import { convertScss, cx, emotionCss, ScssProp } from 'ui/scss';

const base = emotionCss({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface CenterProps extends React.ComponentPropsWithoutRef<'div'>, ScssProp {}

export function Center({ scss, ...props }: CenterProps) {
  return <div className={cx(base, convertScss(scss))} {...props} />;
}
