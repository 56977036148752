import { getValidTemplateId } from '@sr/templates/src';
import { signInAnonymously } from 'firebase/auth';
import { createBlankResume } from 'api/resume';
import { createUser } from 'api/user';
import { auth } from 'utils/firebase/auth';

export async function createUserAndResume(searchParams: URLSearchParams) {
  const templateId = getTemplateId(searchParams);

  // Create anonymous auth user
  const userCredential = await signInAnonymously(auth);

  // Create database user for auth user
  const user = await createUser(userCredential.user.uid);

  // Add blank resume to new user
  const resume = await createBlankResume({
    templateId,
    userId: user.id,
    addDefaultPhoto: true,
  });

  return resume;
}

// Gets the templateId from the URL if the user preselected one
function getTemplateId(query: URLSearchParams) {
  const tid = query.get('templateid');
  return getValidTemplateId(tid);
}
