import { ExperimentId, getCookieName } from '@sr/experiments/';
import Cookies from 'js-cookie';
import { isDev } from './env-checks';

// Get the variant stored in an experiment cookie
export function getExperimentCookie(experimentId: ExperimentId) {
  return Cookies.get(getCookieName(experimentId));
}

// Set the variant stored in an experiment cookie
export function setExperimentCookie(
  experimentId: ExperimentId,
  variant: string
) {
  Cookies.set(getCookieName(experimentId), variant, getDefaultCookieOptions());
}

const GA_PURCHASED_COOKIE_NAME = 'sr-has-purchased';
const GA_PURCHASED_COOKIE_VALUE = 'true';

// Stores if a Google Analytics purchase event has been triggered
export function getGAPurchasedCookie(): boolean {
  const value = Cookies.get(GA_PURCHASED_COOKIE_NAME);
  if (value === GA_PURCHASED_COOKIE_VALUE) return true;
  return false;
}

export function setGAPurchasedCookie() {
  Cookies.set(
    GA_PURCHASED_COOKIE_NAME,
    GA_PURCHASED_COOKIE_VALUE,
    getDefaultCookieOptions()
  );
}

function getDefaultCookieOptions() {
  return {
    // This required for development because localhost isn't considered a valid domain by cookies
    domain: isDev() ? '' : `.${window.location.hostname}`,
    expires: 90,
  };
}

const DISCOUNT_CODE_COOKIE_NAME = 'sr-dcode';

// Checks if the user was offered a discount code on the website
export function getDiscountCode(): string | undefined {
  return Cookies.get(DISCOUNT_CODE_COOKIE_NAME);
}
