import React from 'react';
import { PageLogo } from './PageLogo';
import { Box } from 'ui';
import { Anchor } from 'ui/elements/Link';

export function PageNotFound() {
  return (
    <Box
      scss={{
        position: 'relative',
        backgroundImage: `url(https://assets.standardresume.co/f_auto,q_auto/v1/app/404/404bg_dowljw.png)`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box scss={{ textAlign: 'center' }}>
        <Box
          scss={{
            fontSize: 60,
            fontWeight: 'bold',
            marginBottom: 40,
            lineHeight: 1,
          }}
        >
          Hmmm...
        </Box>
        <Box
          scss={{
            lineHeight: 1.5,
            fontSize: 18,
            marginBottom: 24,
          }}
        >
          Sorry, we coudln't find what
          <br /> you were looking for.
        </Box>
        <Anchor href="/" scss={{ fontSize: 18 }}>
          Back to Homepage
        </Anchor>
      </Box>
      <Anchor
        href="/"
        scss={{
          position: 'absolute',
          left: 32,
          bottom: 32,
        }}
      >
        <PageLogo />
      </Anchor>
    </Box>
  );
}
