import React, { useMemo } from 'react';
import { Box, Label } from 'ui';
import { ChevronDownIcon } from 'ui/icons';
import { ScssObject, convertScss } from 'ui/scss';

interface SelectCustomProps {
  label?: string;
  containerScss?: ScssObject;
  labelScss?: ScssObject;
  selectScss?: ScssObject;
}

export interface SelectProps
  extends React.ComponentPropsWithRef<'select'>,
    SelectCustomProps {}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ label, name, containerScss, labelScss, selectScss, ...props }, ref) => {
    const styles = useMemo(
      () => convertScss({ ...selectStyles, ...selectScss }),
      [selectScss]
    );
    return (
      <Box scss={containerScss}>
        {label && (
          <Label htmlFor={name} scss={labelScss}>
            {label}
          </Label>
        )}
        <Box scss={{ position: 'relative' }}>
          <select
            ref={ref}
            name={name}
            id={label ? name : undefined}
            className={styles}
            {...props}
          />
          <ChevronDownIcon
            size={48}
            scss={{ position: 'absolute', right: 0, pointerEvents: 'none' }}
          />
        </Box>
      </Box>
    );
  }
);

const selectStyles: ScssObject = {
  appearance: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  bg: 'white',
  borderRadius: 'small',
  border: 0,
  width: '100%',
  py: 12,
  px: 16,
};
