import { captureException } from '@sentry/react';
import { JobProfession } from '@sr/types';
import mixpanel from 'mixpanel-browser';
import { getGAPurchasedCookie, setGAPurchasedCookie } from './cookies';
import { safeHttpApiRequest } from './http-api-request';
import isBot from './is-bot';
import { getSimplePath } from 'TrackPageviews';

// Initialize the Mixpanel library
try {
  const MIXPANEL_PROJECT_TOKEN = process.env.PUBLIC_MIXPANEL_TOKEN;
  const MIXPANEL_PROXY_DOMAIN = 'https://mpp.standardresume.co';

  if (!MIXPANEL_PROJECT_TOKEN) {
    throw new Error('Missing process.env.PUBLIC_MIXPANEL_TOKEN');
  }

  mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
    api_host: MIXPANEL_PROXY_DOMAIN,
    ignore_dnt: true,
  });
} catch (error) {
  captureException(error, { tags: { noFeedback: true } });
}

type LiImportProps = {
  resumeId: string;
  userId: string;
  fillType: string;
  URL?: string;
  name?: string;
  company?: string;
};

const trackEvent = {
  accountCreated(props: {
    $first_name: string;
    $last_name: string;
    $email: string;
    selfReportedReferrer: string | undefined;
    userId: string;
    profession?: JobProfession;
  }) {
    trackGaEvent('created_account');
    trackMixpanelEvent('Account Created', {
      selfReportedReferrer: props.selfReportedReferrer,
    });
    const { userId, ...rest } = props;
    identifyMixpanelUser(userId, {
      ...rest,
      'Is Anonymous': 'False',
      isPro: false,
    });
    identifyHotjarUser(userId, { email: props.$email });
    return createCustomerioCustomer({
      firstName: props.$first_name,
      lastName: props.$last_name,
      email: props.$email,
      selfReportedReferrer: props.selfReportedReferrer || '',
      profession: props.profession || '',
    });
  },

  experimentStarted(props: {
    experimentId: string;
    experimentName: string;
    userId: string;
    variant: string;
  }) {
    const { experimentId, experimentName, userId, variant } = props;
    // This is Mixpanels built in premium experiment feature
    trackMixpanelEvent('$experiment_started', {
      'Experiment name': `(${experimentId}): ${experimentName}`,
      'Variant name': variant,
    });

    // Also track the experiment manually in case we lose access to premium experiments
    trackMixpanelEvent('SR Experiment Started', {
      'Experiment ID': experimentId,
      'Experiment name': experimentName,
      'Variant name': variant,
    });
    appendToMixpanelUser('Experiments', `${experimentId}-${variant}`);

    // Send experiment data to Hotjar for easier heatmap/recording filtering
    trackHotjarEvent(`${experimentId}-started`);
    identifyHotjarUser(userId, { [experimentId]: variant });
  },

  linkedInImportError(props: LiImportProps) {
    trackMixpanelEvent('LinkedIn Import Error', props);
    trackHotjarEvent('linkedin_import_error');
  },

  linkedInImportStarted(props: LiImportProps) {
    trackGaEvent('imported_linkedin');
    trackMixpanelEvent('LinkedIn Import Started', props);
    trackHotjarEvent('linkedin_import_started');
  },

  linkedInImportSuccess(props: LiImportProps) {
    const { userId, ...eventProps } = props;
    trackMixpanelEvent('LinkedIn Import Success', eventProps);
    identifyMixpanelUser(userId, {
      'Imported LinkedIn': 'True',
    });
  },

  nextReviewMessage(props: { groupId: string | null }) {
    trackMixpanelEvent('Next Review Message', props);
    trackHotjarEvent('next_review_message');
  },

  pageview(props: { path: string }) {
    // Ignore pageviews from known bots
    if (!isBot()) {
      const simplePath = getSimplePath(props.path);
      trackMixpanelEvent('Pageview', { simplePath });
    }
  },

  previousReviewMessage(props: { groupId: string | null }) {
    trackMixpanelEvent('Previous Review Message', props);
    trackHotjarEvent('previous_review_message');
  },

  productViewed(props: { referringLink: string | null }) {
    trackMixpanelEvent('Product Viewed', props);
  },

  professionSelected(props: { userId: string; profession: JobProfession }) {
    identifyMixpanelUser(props.userId, {
      profession: props.profession,
    });
  },

  // Sends purchase event to GA4. Mixpanel and Customer.io use server side
  // tracking for this for accuracy. GA doesn't support server side events.
  gaPurchase(value: number) {
    // Keep track of when this event has fired to avoid double counting purchases
    const purchaseEventAlreadyFired = getGAPurchasedCookie();

    // Only fire event if it hasn't already fired
    if (!purchaseEventAlreadyFired) {
      // Send purchase event to Google Analytics (used for Google Ads)
      trackGaEvent('purchase', {
        currency: 'USD',
        value,
      });

      // Send event to Mixpanel for auditing purchase events
      trackMixpanelEvent('GA Purchase Event Fired', { amount: value });

      // Set the cookie so this event isn't counted again
      setGAPurchasedCookie();
    }
  },

  reviewGroupSelected(props: { groupId: string }) {
    trackMixpanelEvent('Review Group Selected', props);
    trackHotjarEvent('review_group_selected');
  },

  reviewMessageShowMore(props: { groupId: string; ruleTitle: string }) {
    trackMixpanelEvent('Review Message Show More', props);
    trackHotjarEvent('review_message_show_more');
  },

  reviewResumeShared(props: { resumeId: string; source: string }) {
    trackMixpanelEvent('Review Resume Shared', props);
  },

  reviewUpsellClicked(props: { groupId: string }) {
    trackMixpanelEvent('Review Upsell Clicked', props);
    trackHotjarEvent('review_upsell_clicked');
  },

  reviewUpsellViewed(props: { groupId: string }) {
    trackMixpanelEvent('Review Upsell Viewed', props);
    trackHotjarEvent('review_upsell_viewed');
  },

  setupCompleted() {
    return trackMixpanelEventPromise('Setup Completed', {});
  },

  signedIn(userId: string) {
    identifyMixpanelUser(userId);
    trackMixpanelEvent('Signed In');
    trackGaEvent('login');
    identifyGaUser(userId);
    identifyHotjarUser(userId, {});
  },

  signedOut() {
    trackMixpanelEvent('Signed Out');
    mixpanel.reset();
  },

  signedUp(props: { referredBy: string | undefined; userId: string }) {
    trackGaEvent('sign_up');
    identifyGaUser(props.userId);
    trackMixpanelEvent('Signed Up');
    identifyHotjarUser(props.userId, {});
    identifyMixpanelUser(props.userId, {
      $created: new Date().toISOString(),
      id: props.userId,
      'Date Created': new Date().toISOString(),
      'Is Anonymous': 'True',
      'Referred By': props.referredBy,
    });
  },

  startedMagicFillFlow() {
    trackMixpanelEvent('Started Magic Fill flow');
    trackHotjarEvent('started_magic_fill_flow');
  },

  updateReviewScore(score: number) {
    try {
      if (!isBot()) {
        mixpanel.people.set({ 'Review Score (Latest)': score });
        mixpanel.people.set_once({ 'Review Score (Initial)': score });
      }
    } catch (error) {
      captureException(error, { tags: { noFeedback: true } });
    }
  },

  upgradedToMarkdown(resumeId: string) {
    trackMixpanelEvent('Upgraded to Markdown', { resumeId });
  },
};

// Wraps mixpanel.track in a try/catch for safety
type MpEvents =
  | 'Account Created'
  | 'GA Purchase Event Fired'
  | 'LinkedIn Email Requested'
  | 'LinkedIn Import Error'
  | 'LinkedIn Import Invalid URL'
  | 'LinkedIn Import Started'
  | 'LinkedIn Import Success'
  | 'LinkedIn Profiles Status'
  | 'Magic Fill Started'
  | 'Magic Fill Succeeded'
  | 'Magic Fill Failed'
  | 'Magic Fill Wrong Person'
  | 'Next Review Message'
  | 'Pageview'
  | 'Previous Review Message'
  | 'Product Viewed'
  | 'Review Group Selected'
  | 'Review Message Show More'
  | 'Review Resume Shared'
  | 'Review Upsell Clicked'
  | 'Review Upsell Viewed'
  | 'Setup Completed'
  | 'Signed In'
  | 'Signed Out'
  | 'Signed Up'
  | 'SR Experiment Started'
  | 'Started Magic Fill flow'
  | 'Upgraded to Markdown'
  | '$experiment_started';
function trackMixpanelEvent(event: MpEvents, properties?: Dict): void {
  try {
    if (!isBot()) {
      mixpanel.track(event, properties);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

// Returns a promise that resolves when the event has been received by Mixpanel
// It won't block waiting for Mixpanel for more than timeout milliseconds
// It always resolves (never rejects) to avoid tracking breaking the app
function trackMixpanelEventPromise(
  event: MpEvents,
  properties?: Dict,
  timeout = 300
): Promise<void> {
  return new Promise<void>((resolve) => {
    try {
      if (!isBot()) {
        mixpanel.track(event, properties, () => {
          resolve();
        });
        window.setTimeout(() => {
          resolve();
        }, timeout);
      } else {
        resolve();
      }
    } catch (error) {
      captureException(error, { tags: { noFeedback: true } });
      resolve();
    }
  });
}

// Untyped version of trackMixpanelEvent to use in v1 app
export function trackMixpanelUntypedEvent(
  event: string,
  properties?: Dict
): void {
  try {
    if (!isBot()) {
      mixpanel.track(event, properties);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

// Wraps Google Analytics event tracking in try/catch for safety
type GaEvents =
  | 'created_account'
  | 'imported_linkedin'
  | 'login'
  | 'purchase'
  | 'sign_up';
function trackGaEvent(eventName: GaEvents, properties?: Dict) {
  try {
    const gtag = (window as any).gtag;
    if (gtag && !isBot()) {
      gtag('event', eventName, properties || {});
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

function identifyGaUser(userId: string) {
  try {
    const gtag = (window as any).gtag;
    if (gtag && !isBot()) {
      gtag('config', 'GA_MEASUREMENT_ID', {
        user_id: userId,
      });
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

// Wraps mixpanel.identify in a try/catch for safety and sets people properties.
// Registers super properties to all events and people properties to their profile.
function identifyMixpanelUser(
  userId: string,
  traits?: {
    // This is a duplicate of $created that Segment was using so we will
    // contninue to populate to not break backwards compatibility.
    'Date Created'?: string;
    'Imported LinkedIn'?: 'True';
    'Is Anonymous'?: 'True' | 'False';
    'Referred By'?: string;
    id?: string;
    $created?: string;
    $first_name?: string;
    $last_name?: string;
    $email?: string;
    selfReportedReferrer?: string;
    isPro?: boolean;
    profession?: JobProfession;
  }
) {
  try {
    if (!isBot()) {
      mixpanel.identify(userId);
      if (traits) {
        const utmParams = getUtmParams();
        const traitsAndUtm = { ...traits, ...utmParams };
        mixpanel.register(traitsAndUtm);
        mixpanel.people.set(traitsAndUtm);
      }
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

// Append a value to a list-valued people analytics property
function appendToMixpanelUser(property: string, value: string) {
  try {
    if (!isBot()) {
      mixpanel.people.append(property, value);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

// Add an isPro super property to all events for more granular analysis
export function trackIsProMixpanel(isPro: boolean) {
  try {
    if (!isBot()) {
      const traits = { isPro };
      mixpanel.register(traits);
      mixpanel.people.set(traits);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

async function createCustomerioCustomer(customerData: {
  firstName: string;
  lastName: string;
  email: string;
  selfReportedReferrer: string;
  profession: string;
}) {
  if (isBot()) {
    return Promise.resolve();
  } else {
    return safeHttpApiRequest('customerio/customer', {
      method: 'POST',
      json: customerData,
    });
  }
}

async function updateCustomerioCustomer(attributes: Dict) {
  if (isBot()) {
    return Promise.resolve();
  } else {
    // Uses function authentication to get userId
    return safeHttpApiRequest('customerio/customer', {
      method: 'PATCH',
      json: attributes,
    });
  }
}

async function trackCustomerioEvent(eventName: string, properties: Dict) {
  if (isBot()) {
    return Promise.resolve();
  } else {
    return safeHttpApiRequest('customerio/event', {
      method: 'POST',
      json: {
        eventName,
        properties,
      },
    });
  }
}

function trackHotjarEvent(eventName: string) {
  try {
    const hj = (window as any).hj;
    if (hj && !isBot()) {
      hj('event', eventName);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

function identifyHotjarUser(userId: string, traits: { [key: string]: string }) {
  try {
    const hj = (window as any).hj;
    if (hj && !isBot()) {
      hj('identify', userId, traits);
    }
  } catch (error) {
    captureException(error, { tags: { noFeedback: true } });
  }
}

type UtmParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_name?: string;
  utm_campaign?: string;
  utm_adg?: string;
};

// Get UTM params from super properties to add to user
function getUtmParams() {
  const params: UtmParams = {};
  const keys: (keyof UtmParams)[] = [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_name',
    'utm_campaign',
    'utm_adg',
  ];
  keys.forEach((key) => {
    const value = mixpanel.get_property(key);
    if (value) {
      params[key] = value;
    }
  });
  return params;
}

// Used as generic type for event properties
interface Dict {
  [key: string]: any;
}

export default trackEvent;
