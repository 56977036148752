import React, { useMemo } from 'react';
import { Box, Label } from 'ui';
import { ScssObject, convertScss } from 'ui/scss';

interface InputCustomProps {
  label?: string;
  containerScss?: ScssObject;
  labelScss?: ScssObject;
  inputScss?: ScssObject;
}

export interface InputProps
  extends React.ComponentPropsWithRef<'input'>,
    InputCustomProps {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ label, name, containerScss, labelScss, inputScss, ...props }, ref) => {
    const styles = useMemo(
      () => convertScss({ ...inputStyles, ...inputScss }),
      [inputScss]
    );
    return (
      <Box scss={containerScss}>
        {label && (
          <Label htmlFor={name} scss={labelScss}>
            {label}
          </Label>
        )}
        <input
          ref={ref}
          name={name}
          id={label ? name : undefined}
          className={styles}
          {...props}
        />
      </Box>
    );
  }
);

const inputStyles: ScssObject = {
  appearance: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  bg: 'white',
  borderRadius: 'small',
  border: 0,
  width: '100%',
  py: 12,
  px: 16,
  '::placeholder': {
    color: 'black30',
  },
};
