import { Theme } from 'ui/scss';

export const theme: Theme = {
  breakpoints: ['768px', '1024px', '1280px'],

  space: {
    setupPagePadding: 24,
  },

  sizes: {
    container: 1000,
    smallContainer: 650,
  },

  colors: {
    blue: 'var(--sr-colors-blue)',
    blue70: 'var(--sr-colors-blue-70)',
    blue50: 'var(--sr-colors-blue-50)',
    blue10: 'var(--sr-colors-blue-10)',
    buttonGray: 'var(--sr-colors-button-gray)',
    surfaceGray: 'var(--sr-colors-surface-gray)',
    success: 'var(--sr-colors-success)',
    error: 'var(--sr-colors-error)',
    warning: 'var(--sr-colors-warning)',
    black: 'var(--sr-colors-black)',
    black70: 'var(--sr-colors-black-70)',
    black50: 'var(--sr-colors-black-50)',
    black30: 'var(--sr-colors-black-30)',
    white: 'var(--sr-colors-white)',
  },

  fonts: {
    body: 'var(--sr-fonts-body)',
    heading: 'var(--sr-fonts-heading)',
  },

  fontSizes: {
    base: 'var(--sr-font-sizes-base)',
    heading: 'var(--sr-font-sizes-heading)',
  },

  fontWeights: {
    body: 300,
    bold: 600,
    heading: 600,
  },

  lineHeights: {
    body: 'var(--sr-line-heights-body)',
    heading: 'var(--sr-line-heights-heading)',
  },

  radii: {
    small: 'var(--sr-radii-small)',
  },

  text: {
    base: {
      fontFamily: 'body',
      fontSize: 'base',
      fontWeight: 'body',
      lineHeight: 'body',
      color: 'black',
    },
    heading: {
      fontFamily: 'heading',
      fontSize: 'base',
      fontWeight: 'heading',
      lineHeight: 'heading',
      color: 'black',
      textAlign: 'center',
    },
  },

  styles: {
    root: {
      variant: 'text.base',
      backgroundColor: 'surfaceGray',
    },

    // Text
    text: {
      base: {
        variant: 'text.base',
      },
    },
    heading: {
      base: {
        variant: 'text.heading',
      },
      setup: {
        variant: 'styles.heading.base',
        fontSize: 18,
        lineHeight: 1.5,
        maxWidth: 313,
        mx: 'auto',
      },
    },

    // Buttons
    button: {
      base: {
        alignItems: 'center',
        appearance: 'none',
        outline: 'none',
        lineHeight: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'bold',
        textDecoration: 'none',
        borderRadius: 'small',
        cursor: 'pointer',
        px: 16,
      },
      solid: {
        variant: 'styles.button.base',
      },
      outline: {
        variant: 'styles.button.base',
      },
      text: {
        variant: 'styles.button.base',
      },

      primary: {
        variant: 'styles.button.base',
        bg: 'blue',
        color: 'white',
        ':hover': {
          opacity: 0.9,
        },
      },
      primaryWithIcon: {
        variant: 'styles.button.primary',
        justifyContent: 'space-between',
        px: 12,
        py: 8,
      },
      setupAddItem: {
        variant: 'styles.button.base',
        bg: 'buttonGray',
        color: 'blue',
        fontWeight: 'normal',
      },
      icon: {
        variant: 'styles.button.base',
        bg: 'buttonGray',
        p: 0,
        svg: {
          display: 'block',
        },
      },
    },

    // Links
    link: {
      base: {},
      setupPrimary: {
        variant: 'styles.button.primaryWithIcon',
        textDecoration: 'none',
      },
    },

    // Forms
    input: {
      base: {
        display: 'block',
        width: '100%',
        appearance: 'none',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        border: '1px solid',
        borderColor: 'white',
        borderRadius: 'small',
        bg: 'white',
        py: 12,
        px: 16,
      },
    },
    textarea: {
      base: {},
    },
    label: {
      base: {
        display: 'block',
        fontWeight: 'bold',
        mb: 8,
      },
    },

    // Layout
    flex: {
      base: {
        display: 'flex',
      },
      center: {
        variant: 'styles.flex.base',
        alignItems: 'center',
        justifyContent: 'center',
      },
      verticallyCenter: {
        variant: 'styles.flex.base',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
      },
    },
    grid: {
      base: {
        display: 'grid',
      },
    },
  },
};

export const spacing = {
  pagePaddingX: [12, 16, 24],
  pagePaddingY: [40, 100],
};

export const animations = {
  fadeIn: {
    animation: 'fadein ease-in-out 800ms backwards',
    '@media(prefers-reduced-motion: reduce)': {
      animation: 'none',
    },
  },
  fadeInLong: {
    animation: 'fadein ease-in-out 3000ms backwards',
    '@media(prefers-reduced-motion: reduce)': {
      animation: 'none',
    },
  },
};
