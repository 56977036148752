import type { FirebaseApp } from 'firebase/app';
import firebase from 'firebase/compat/app';

const config = {
  apiKey: process.env.PUBLIC_FIREBASE_API_KEY,
  appId: process.env.PUBLIC_FIREBASE_APP_ID,
  authDomain: process.env.PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.PUBLIC_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.PUBLIC_FIREBASE_STORAGE_BUCKET,
};

let app: FirebaseApp;

if (!firebase.apps.length) {
  app = firebase.initializeApp(config);
} else {
  app = firebase.apps[0];
}

export { app };
