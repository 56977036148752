export function isDev(): boolean {
  return process.env.PUBLIC_ENVIRONMENT === 'develop';
}

export function isStaging(): boolean {
  return process.env.PUBLIC_ENVIRONMENT === 'staging';
}

export function isProduction(): boolean {
  return process.env.PUBLIC_ENVIRONMENT === 'production';
}
