import { TemplateId } from '@sr/types';

// The default template to use for new resumes
export const DEFAULT_TEMPLATE_ID: Readonly<TemplateId> = 'san-juan';

// Used to generate ALL_TEMPLATE_IDS
// This ensures that all TemplateId values and no others are included
const ALL_TEMPLATE_IDS_OBJECT: Readonly<Record<TemplateId, boolean>> = {
  'san-juan': true,
  parker: true,
  georgia: true,
  seymore: true,
  pender: true,
  keefer: true,
  venables: true,
  eton: true,
  alexander: true,
  cordova: true,
  arbutus: true,
  yew: true,
};

export const ALL_TEMPLATE_IDS = Object.keys(
  ALL_TEMPLATE_IDS_OBJECT
) as Readonly<TemplateId[]>;
