import { TemplateId } from '@sr/types';
import { DEFAULT_TEMPLATE_ID, ALL_TEMPLATE_IDS } from './constants';

export function isValidTemplateId(id: string): boolean {
  return ALL_TEMPLATE_IDS.includes(id as TemplateId);
}

// Catch all function to get TemplateId from untrusted user/url string
export function getValidTemplateId(id: string | null): TemplateId {
  if (typeof id === 'string') {
    return isValidTemplateId(id) ? (id as TemplateId) : DEFAULT_TEMPLATE_ID;
  } else {
    return DEFAULT_TEMPLATE_ID;
  }
}

export function nextTemplateId(templateId: TemplateId): TemplateId {
  const currentIndex = ALL_TEMPLATE_IDS.findIndex((id) => id === templateId);
  const nextId =
    currentIndex === ALL_TEMPLATE_IDS.length - 1
      ? ALL_TEMPLATE_IDS[0]
      : ALL_TEMPLATE_IDS[currentIndex + 1];
  return nextId;
}

export function previousTemplateId(templateId: TemplateId): TemplateId {
  const currentIndex = ALL_TEMPLATE_IDS.findIndex((id) => id === templateId);
  const previousId =
    currentIndex === 0
      ? ALL_TEMPLATE_IDS[ALL_TEMPLATE_IDS.length - 1]
      : ALL_TEMPLATE_IDS[currentIndex - 1];
  return previousId;
}
