import * as Sentry from '@sentry/browser';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom-6';
import trackEvent from 'utils/analytics';

const TrackPageviews = React.memo(function TrackPageViews() {
  let location = useLocation();

  const path = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    trackEvent.pageview({ path });
    Sentry.setTag('simplePath', getSimplePath(path));
  }, [path]);

  return null;
});

// Matches the UUIDs we use for ids
// eg: /v2/setup/{resumeId}/welcome matches /{resumeId}
export const UUID_REGEX = /\/[A-Za-z0-9_-]{21}/g;

// Matches Firebase Realtime Database push IDs
// https://firebase.googleblog.com/2015/02/the-2120-ways-to-ensure-unique_68.html
export const FIREBASE_PUSH_ID_REGEX = /\/[A-Za-z0-9_-]{20}/g;

// Used to remove double slashes from paths. Needed when an id has surrounding
// slashes like /:id/ and the ID regexes replace it with // instead of /.
const DOUBLE_SLASH_REGEX = /\/\//g;

// Removes IDs from the path for easier analysis
export function getSimplePath(path: string) {
  return path
    .replace(UUID_REGEX, '/')
    .replace(FIREBASE_PUSH_ID_REGEX, '/')
    .replace(DOUBLE_SLASH_REGEX, '/');
}

export default TrackPageviews;
