import { memo } from 'react';
import logo from '../logo.svg';
import { Image } from 'ui/html-elements';
import { spacing } from 'ui/theme';

export const PageLogo = memo(function PageLogo() {
  return (
    <Image
      src={logo}
      scss={{
        display: ['none', 'block'],
        position: 'fixed',
        bottom: spacing.pagePaddingX,
        left: spacing.pagePaddingX,
      }}
    />
  );
});
