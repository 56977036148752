import React, { useEffect } from 'react';

interface DelayedFullscreenSpinnerProps {
  delay?: number;
}

// This component renders nothing and modifies the visibility/class of #fullscreen-spinner,
// which is not rendered within the React tree.
//
// It adds a small delay before removing a single instance of the visible class.
// That way if it is rendered again right after, a second visible class is added
// and then removed. This stops the spinner from flickering when it is loaded
// back to back in fast succession, which happens with nested lazy/context.

function show(el: HTMLElement) {
  el.className = `${el.className} visible`;
}

// Only removes one instance of "visible"
function hide(el: HTMLElement) {
  el.className = el.className.replace('visible', '').trim();
}

export const DelayedFullscreenSpinner = React.memo(
  function DelayedFullscreenSpinner({
    delay: delayProp,
  }: DelayedFullscreenSpinnerProps) {
    useEffect(() => {
      const delay = delayProp ?? 500;
      const spinner = document.getElementById('fullscreen-spinner');
      if (!spinner) return;

      const id = setTimeout(() => {
        show(spinner);
      }, delay ?? 0);

      return () => {
        clearTimeout(id);
        setTimeout(() => {
          hide(spinner);
        }, 500 + delay);
      };
    }, []); // eslint-disable-line

    return null;
  }
);
