import { DEFAULT_CUSTOMIZATIONS } from '@sr/templates/src';
import {
  About,
  BlockListSection,
  BlockListSectionItem,
  ContentType,
  DetailSection,
  DetailSectionItem,
  InlineListSection,
  Resume,
  ResumeVisibility,
  TemplateId,
} from '@sr/types';
import { nanoid } from 'nanoid';

const empty = {
  resume({
    id,
    slug,
    visibility,
    owner,
    name,
    isDefault = false,
    firstName = '',
    lastName = '',
    templateId = 'san-juan',
    addDefaultPhoto = false,
  }: {
    id: string;
    slug?: string;
    visibility?: ResumeVisibility;
    owner: string;
    name: string;
    isDefault?: boolean;
    firstName?: string;
    lastName?: string;
    templateId?: TemplateId;
    addDefaultPhoto?: boolean;
  }): Resume {
    const workSectionId = nanoid();
    const educationSectionId = nanoid();

    const resume: Resume = {
      id,
      slug: slug || nanoid(),
      visibility: visibility || 'private',
      hideBranding: false,
      hidePhoneAndEmail: false,
      isDefault,
      owner,
      name: name,
      dateCreated: Date.now(),
      style: {
        activeTemplateId: templateId,
        customizations: {
          [templateId]: DEFAULT_CUSTOMIZATIONS[templateId],
        },
      },
      about: empty.about({ firstName, lastName }),
      sectionsOrder: [workSectionId, educationSectionId],
      sections: {
        [workSectionId]: empty.detailSection({
          resumeId: id,
          sectionId: workSectionId,
          contentType: 'work-experience',
          title: 'Work Experience',
          addItem: true,
        }),
        [educationSectionId]: empty.detailSection({
          resumeId: id,
          sectionId: educationSectionId,
          contentType: 'education',
          title: 'Education',
          addItem: true,
        }),
      },
    };

    if (addDefaultPhoto) {
      const DEFAULT_PROFILE_PHOTO_URL = `https://assets.standardresume.co/image/upload/v1619041495/profile-photos/default-profile-photo_nplcke.png`;
      resume.profilePhotoUrl = DEFAULT_PROFILE_PHOTO_URL;
    }

    return resume;
  },

  about({
    firstName = '',
    lastName = '',
  }: {
    firstName?: string;
    lastName?: string;
  }): About {
    return {
      firstName: firstName,
      lastName: lastName,
      city: '',
      state: '',
      email: '',
      phone: '',
      title: '',
      summary: '',
      website: '',
      website2: '',
    };
  },

  detailSection({
    resumeId,
    sectionId,
    contentType,
    title,
    addItem,
  }: {
    resumeId: string;
    sectionId?: string;
    contentType: ContentType;
    title: string;
    addItem?: boolean;
  }) {
    const section: DetailSection = {
      resumeId,
      sectionId: sectionId || nanoid(),
      type: 'detail',
      contentType,
      title,
    };
    if (addItem) {
      const item = empty.detailSectionItem(nanoid());
      section.items = { [item.id]: item };
      section.itemsOrder = [item.id];
    }
    return section;
  },

  blockListSection({
    resumeId,
    sectionId,
    contentType,
    title,
    addItem,
  }: {
    resumeId: string;
    sectionId: string;
    contentType: ContentType;
    title: string;
    addItem?: boolean;
  }) {
    const section: BlockListSection = {
      resumeId,
      sectionId,
      type: 'block-list',
      contentType,
      title,
    };
    if (addItem) {
      const item = empty.emptyBlockListSectionItem(nanoid());
      section.items = { [item.id]: item };
      section.itemsOrder = [item.id];
    }
    return section;
  },

  inlineListSection({
    resumeId,
    sectionId,
    contentType,
    title,
  }: {
    resumeId: string;
    sectionId: string;
    contentType: ContentType;
    title: string;
  }): InlineListSection {
    return {
      resumeId,
      sectionId,
      type: 'inline-list',
      contentType,
      title,
      list: '',
    };
  },

  detailSectionItem(id: string): DetailSectionItem {
    return {
      type: 'detail',
      id,
      title: '',
      subtitle: '',
      website: '',
      isCurrent: false,
      summary: '',
      textType: 'markdown',
    };
  },

  emptyBlockListSectionItem(id: string): BlockListSectionItem {
    return {
      type: 'block-list',
      id,
      primary: '',
      secondary: '',
    };
  },
};

export default empty;
