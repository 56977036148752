import { onValue } from 'firebase/database';
import type { Query } from 'firebase/database';

export function getValue<T>(query: Query): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    onValue(
      query,
      (snapshot) => {
        if (snapshot.exists()) {
          resolve(snapshot.val());
        } else {
          reject('Not found');
        }
      },
      { onlyOnce: true }
    );
  });
}
