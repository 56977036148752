import React from 'react';
import { useNavigate } from 'react-router-dom-6';
import { ArrowLeftIcon, CheckCircleIcon, ChevronRightIcon } from './icons';
import { convertScss } from './scss';
import { Box, Button, Center } from 'ui';

type Props = {
  steps: string[];
  activeStep: string;
  hideBackButton?: boolean;
};

type States = 'incomplete' | 'active' | 'complete';

function getState(activeStep: string, step: string, steps: string[]): States {
  const currentIndex = steps.findIndex((s) => s === step);
  const activeIndex = steps.findIndex((s) => s === activeStep);
  if (currentIndex === activeIndex) return 'active';
  if (currentIndex < activeIndex) return 'complete';
  return 'incomplete';
}

export const ProgressSteps = React.memo(function ProgressSteps({
  steps,
  activeStep,
  hideBackButton,
}: Props) {
  return (
    <div className={containerStyles}>
      {!hideBackButton && <BackButton />}
      {steps.map((step, index) => (
        <React.Fragment key={step}>
          <Step state={getState(activeStep, step, steps)}>{step}</Step>
          {index !== steps.length - 1 && <Separator />}
        </React.Fragment>
      ))}
    </div>
  );
});

const containerStyles = convertScss({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  overflowX: 'auto',
  borderBottom: '1px solid',
  borderColor: 'buttonGray',
  minHeight: 65,
  label: 'ProgressContainer',
});

function Step({
  children,
  state,
}: {
  children: React.ReactNode;
  state: States;
}) {
  return (
    <Box
      scss={{
        display: state === 'active' ? 'flex' : ['none', 'flex'],
        alignItems: 'center',
        px: 10,
        py: 6,
        mx: 24,
        fontWeight: state === 'active' ? 'bold' : 'body',
        borderRadius: 'small',
        bg: state === 'active' ? 'buttonGray' : 'transparent',
        color:
          state === 'complete'
            ? 'success'
            : state === 'active'
            ? 'black'
            : 'black50',
      }}
    >
      {state === 'complete' && <CheckCircleIcon scss={{ mr: 4 }} />}
      {children}
    </Box>
  );
}

function Separator() {
  return <ChevronRightIcon scss={{ display: ['none', 'flex'] }} />;
}

function BackButton() {
  const navigate = useNavigate();
  return (
    <Center scss={{ position: 'absolute', left: 16 }}>
      <Button variant="icon" onClick={() => navigate(-1)} tabIndex={-1}>
        <ArrowLeftIcon />
      </Button>
    </Center>
  );
}
