import React, { useEffect, useState } from 'react';

interface DelayedRenderProps {
  delay?: number;
  children: React.ReactNode;
}

export function DelayedRender({ delay, children }: DelayedRenderProps) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setVisible(true), delay ?? 500);
    return () => clearTimeout(timeout);
  }, [delay]);

  if (!visible) return null;

  return <>{children}</>;
}
