import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Navigate, useSearchParams } from 'react-router-dom-6';
import { linkedInImageUrl } from 'setup/linkedin/ui';
import { NextButton, Header, SimplePage } from 'setup/ui';
import { createUserAndResume } from 'setup/utils/create-user-and-resume';
import { Box } from 'ui';
import { Ol } from 'ui/html-elements';
import { hotkeysConfigs } from 'utils/use-hotkeys';

export default function NewUser() {
  const [searchParams] = useSearchParams();
  const [resumeId, setResumeId] = useState<string | null>(null);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  useHotkeys('enter', () => setReadyToNavigate(true), hotkeysConfigs.default);

  // Setup the new user and resume
  useEffect(() => {
    async function setupUser() {
      setIsCreatingUser(true);
      const resume = await createUserAndResume(searchParams);
      setResumeId(resume.id);
    }
    if (!isCreatingUser) {
      setupUser();
    }
  }, [isCreatingUser, searchParams]);

  // Preload the LinkedIn image for the next page
  useEffect(() => {
    const img = new Image();
    img.src = linkedInImageUrl;
  }, []);

  if (resumeId && readyToNavigate) {
    return <Navigate to={`../${resumeId}/profession`} />;
  }

  return (
    <SimplePage animate hideBackButton={true}>
      <Header
        icon={<Box scss={{ fontSize: 32 }}>👋</Box>}
        scss={{ maxWidth: 475 }}
      >
        <Box>Welcome to Standard Resume</Box>
        <Box scss={{ fontWeight: 'body', mt: 16, fontSize: 'base' }}>
          You're only a few minutes away from an impressive resume. Here's how
          it works.
        </Box>
        <Ol
          scss={{
            textAlign: 'left',
            mt: 48,
            maxWidth: 390,
            mx: 'auto',
            fontSize: 'base',
          }}
        >
          <li>
            <Box>Setup & import</Box>
            <Box scss={{ fontWeight: 'body', mb: 20, color: 'black70', mt: 4 }}>
              Import from LinkedIn or start from scratch.
            </Box>
          </li>
          <li>
            <Box>Review & edit</Box>
            <Box scss={{ fontWeight: 'body', mb: 20, color: 'black70', mt: 4 }}>
              Review the import and add what's missing.
            </Box>
          </li>
          <li>
            <Box>Pick a template & download</Box>
            <Box scss={{ fontWeight: 'body', mb: 20, color: 'black70', mt: 4 }}>
              Pick a template and download a PDF, or share your web resume.
            </Box>
          </li>
        </Ol>
      </Header>
      <NextButton
        hotkey="enter"
        loading={readyToNavigate && !resumeId}
        onClick={() => setReadyToNavigate(true)}
      >
        Get started
      </NextButton>
    </SimplePage>
  );
}
