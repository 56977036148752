import { captureException } from '@sentry/react';
import ky, { Options } from 'ky';
import { Sentry, serializeRequest, serializeResponse } from './sentry';
import { auth } from 'utils/firebase/auth';

const httpApiRequest = ky.create({
  timeout: 30 * 1000,
  prefixUrl: '/api/v2/',
  retry: 0,
  hooks: {
    beforeRequest: [
      async (request) => {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error('Missing auth.currentUser');
        const idToken = await currentUser.getIdToken(true);
        if (idToken) {
          request.headers.set('Authorization', `Bearer ${idToken}`);
        }
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (!response.ok) {
          // Add Sentry context for easier debugging
          Sentry.setContext('Request', serializeRequest(request));
          Sentry.setContext('Response', serializeResponse(response));
        }
      },
    ],
  },
});

// httpApiRequest that handles errors to assure nothing is ever thrown
// This is used for requests like tracking where we don't want to stop execution
// of the program when they fail.
export async function safeHttpApiRequest(url: string, options?: Options) {
  try {
    const value = await httpApiRequest(url, options);
    return value;
  } catch (error) {
    captureException(error, {
      tags: { noFeedback: true },
    });
  }
}

export default httpApiRequest;
