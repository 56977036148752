import { Integrations } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { Event } from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import getUserLocale from 'get-user-locale';
import { getSimplePath } from '../TrackPageviews';

export function initializeSentry() {
  Sentry.init({
    dsn: 'https://d091ac5f12be4cba8c04bc56feabb961@sentry.io/2749498',
    environment: process.env.PUBLIC_ENVIRONMENT,
    release: process.env.PUBLIC_SENTRY_RELEASE || 'development',
    normalizeDepth: 5,
    ignoreErrors,
    denyUrls,
    tracesSampler: (samplingContext) => {
      // Always include include traces with a parent
      if (samplingContext.parentSampled) {
        return 1;
      }

      // Apply lower sampling rates to frequently called or unimportant transactions
      switch (samplingContext.transactionContext.name) {
        case '/':
        case '/resumes':
          return 0.2;
        case '/v2/setup':
          return 0.5;
        case '/resumes/write':
        case '/resumes/preview':
          return 0.1;
        default:
          return 0.2;
      }
    },

    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [
          /^\//,
          'localhost',
          'app.standardresume.co',
          'app.standardresume.dev',
        ],
        beforeNavigate: (context) => {
          return {
            ...context,
            // Replace IDs in URLs for cleaner context
            name: getSimplePath(window.location.pathname),
          };
        },
      }),
      new Integrations.Breadcrumbs({
        dom: {
          serializeAttribute: 'data-testid',
        },
      }),
    ],
    initialScope: {
      tags: {
        locale: getUserLocale(),
      },
    },

    beforeSend(event, hint) {
      // Help Sentry group similar errors with different names/messages
      const error = hint?.originalException;
      if (error && typeof error === 'object') {
        if (error?.name === 'ChunkLoadError') {
          event.fingerprint = ['chunkloaderror'];
        }
        if (error?.message.match(/Confirmed chunk/i)) {
          event.fingerprint = ['confirmed-chunkloaderror'];
        }
        if (error?.message.match(/permission_denied/i)) {
          event.fingerprint = ['permission-denied'];
        }
      }

      // Only show the feedback modal if the event is an exception
      // Don't show if error will be handled by a React error boundary
      // Don't show if the noFeedback tag is present
      if (
        event.exception &&
        !isReactComponentError(event) &&
        !event?.tags?.noFeedback
      ) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          subtitle: 'Sorry for the inconvenience — our team as been notified.',
          subtitle2:
            'In the meantime, try refreshing the page or using Google Chrome.',
        });
      }
      return event;
    },
  });
}

// Copied from: https://docs.sentry.io/platforms/javascript/configuration/filtering/
const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
];

const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

// Check if an event originated from a React component and will be handled
// by a react error boundary.
function isReactComponentError(event: Event) {
  if (event?.contexts?.react) {
    return true;
  }
  return false;
}

export function serializeRequest(req: Request) {
  return {
    headers: Array.from(req.headers.entries()),
    method: req.method,
    url: req.url,
  };
}

export function serializeResponse(res: Response) {
  return {
    headers: Array.from(res.headers.entries()),
    ok: res.ok,
    status: res.status,
    statusText: res.statusText,
    url: res.url,
  };
}

export { Sentry };
