import { Options } from 'react-hotkeys-hook';

export const hotkeysConfigs: { [id: string]: Options } = {
  default: {
    filter: (event: KeyboardEvent) => {
      const tagName = (event.target as HTMLElement)?.tagName;
      return !/^(INPUT|TEXTAREA|SELECT|BUTTON|A)$/.test(tagName);
    },
    filterPreventDefault: false,
  },
  // Only triggers events from textareas
  textareaOnly: {
    filter: (event: KeyboardEvent) => {
      const tagName = (event.target as HTMLElement)?.tagName;
      return tagName === 'TEXTAREA';
    },
    enableOnTags: ['TEXTAREA'],
    filterPreventDefault: false,
  },
  // Allow inputs from inputs. Extends deafult.
  allowInput: {
    filter: (event: KeyboardEvent) => {
      const tagName = (event.target as HTMLElement)?.tagName;
      return !/^(TEXTAREA|SELECT|BUTTON|A)$/.test(tagName);
    },
    enableOnTags: ['INPUT'],
    filterPreventDefault: false,
  },
};
