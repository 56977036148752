/* eslint-disable jsx-a11y/anchor-has-content */

import React from 'react';
import { Link as RRLink } from 'react-router-dom-6';
import { convertScss, ScssObject, ScssProp } from 'ui/scss';

const styles: ScssObject = {
  color: 'blue',
  textDecoration: 'none',
  borderBottom: '1px solid transparent',
  transition: 'border 300ms',
  outline: 'none',
  ':hover, :focus': {
    borderColor: 'blue',
  },
};

interface LinkProps
  extends ScssProp,
    React.ComponentPropsWithoutRef<typeof RRLink> {}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ scss, ...props }, ref) => {
    return (
      <RRLink
        className={convertScss({ ...styles, ...scss })}
        ref={ref}
        {...props}
      />
    );
  }
);

interface AnchorProps extends ScssProp, React.ComponentPropsWithoutRef<'a'> {}

export function Anchor({ scss, ...props }: AnchorProps) {
  return <a className={convertScss({ ...styles, ...scss })} {...props} />;
}
