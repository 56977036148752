import { Header, SplitPage } from '../ui';
import { LinkedInProfile } from './utils';
import { Box, Image, Link, UnstyledButton, Span } from 'ui';
import { ImportIcon, LinkedInIcon, SpinnerIcon } from 'ui/icons';
import { ScssObject } from 'ui/scss';
import { animations } from 'ui/theme';

export const linkedInImageUrl = `https://assets.standardresume.co/f_auto,q_auto/v1/app/setup/linkedin_gm5ada.png`;

export function Layout({
  children,
  title,
  subtext,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
  subtext?: string;
}) {
  return (
    <SplitPage
      left={
        <Box scss={{ width: '100%', ...animations.fadeIn }}>
          <Header icon={<ImportIcon />} align="left" subtext={subtext}>
            {title}
          </Header>
          <Box
            scss={{ alignSelf: 'flex-start', minHeight: 300, width: '100%' }}
          >
            {children}
          </Box>
        </Box>
      }
      right={
        <Image
          src={linkedInImageUrl}
          scss={{
            width: '100%',
            height: 'auto',
          }}
        />
      }
    />
  );
}

export function Profile({
  profile,
  onClick,
  scss,
}: {
  profile: LinkedInProfile;
  onClick: (url: string) => void;
  scss?: ScssObject;
}) {
  return (
    <UnstyledButton
      onClick={() => onClick(profile.url)}
      data-testid="select-profile"
      scss={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        bg: 'buttonGray',
        borderRadius: 'small',
        textAlign: 'left',
        px: [12, 16],
        py: 16,
        transition: 'transform 300ms, background-color 300ms',
        ':hover': {
          cursor: 'pointer',
          transform: 'scale(1.02)',
          bg: 'blue10',
        },
        ':focus': {
          transform: 'scale(1.02)',
          bg: 'blue10',
        },
        ':active': {
          transform: 'scale(1)',
          bg: 'blue10',
        },
        ...scss,
      }}
    >
      <LinkedInIcon scss={{ flexShrink: 0, mr: [12, 16] }} />
      <Box scss={{ overflow: 'hidden' }}>
        <Box
          scss={{
            fontSize: [14, 16],
            fontWeight: 'bold',
            mb: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {profile.title}
        </Box>
        <Box
          scss={{
            fontSize: [12, 14],
            color: 'black50',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {profile.humanizedUrl}
        </Box>
      </Box>
    </UnstyledButton>
  );
}

export function SecondaryLink({
  message,
  linkText,
  linkPath,
  dataTestid,
}: {
  message: string;
  linkText: string;
  linkPath: string;
  dataTestid?: string;
}) {
  return (
    <Box scss={{ mt: 48 }}>
      <Span scss={{ color: 'black50', pr: 4 }}>{message}</Span>
      <Link to={linkPath} data-testid={dataTestid}>
        {linkText}
      </Link>
    </Box>
  );
}

export function LoadingBlock({ children }: { children?: React.ReactNode }) {
  return (
    <Box
      scss={{
        bg: 'buttonGray',
        borderRadius: 'small',
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SpinnerIcon size={50} />
      {children}
    </Box>
  );
}
