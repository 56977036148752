import { Suspense, lazy } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom-6';
import { AuthProvider } from 'context/auth-context';
import { UserProvider } from 'context/user-context';
import ErrorBoundary from 'ErrorBoundary';
import PrivateRoute from 'PrivateRoute';
import RedirectBasedOnAuth from 'setup/RedirectBasedOnAuth';
import NewUser from 'setup/welcome/NewUser';
import TrackPageviews from 'TrackPageviews';
import { PageNotFound } from 'ui';
import { DelayedFullscreenSpinner } from 'ui/DelayedFullscreenSpinner';
import 'utils/firebase/app';
import 'ui/theme.css';

// Prefetch all chunks at low priority. Some specify order to make sure
// the important chunks are preloaded first.
const AboutManual = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/manual/About')
);
const AboutReview = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/review/About')
);
const ChooseImportMethod = lazy(
  () => import(/* webpackPrefetch: 99 */ 'setup/linkedin/ChooseImportMethod')
);
const ContactManual = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/manual/Contact')
);
const ContactReview = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/review/Contact')
);
const CreateAccount = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/account/CreateAccount')
);
const EducationManual = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/manual/Education')
);
const EducationReview = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/review/Education')
);
const NameAndCompany = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/linkedin/NameAndCompany')
);
const ProfileUrl = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/linkedin/ProfileUrl')
);
const Profiles = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/linkedin/Profiles')
);
const Profession = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/welcome/Profession')
);
const TemplatePicker = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/templates/TemplatePicker')
);
const WithResume = lazy(
  () => import(/* webpackPrefetch: 100 */ 'setup/WithResume')
);
const WorkExperienceManual = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/manual/WorkExperience')
);
const WorkExperienceReview = lazy(
  () => import(/* webpackPrefetch: true */ 'setup/review/WorkExperience')
);

export default function App() {
  return (
    <ErrorBoundary fullscreen={true} location="v2-root">
      <BrowserRouter>
        <AuthProvider>
          <TrackPageviews />
          <Suspense fallback={<DelayedFullscreenSpinner />}>
            <AppRoutes />
          </Suspense>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/v2" element={<Navigate to="/v2/setup" replace />} />
      <Route path="/v2/setup">
        <Route path="/" element={<RedirectBasedOnAuth />} />
        <Route path="/new-user" element={<NewUser />} />
        <Route
          path="account"
          element={
            <UserProvider>
              <CreateAccount />
            </UserProvider>
          }
        />
        <PrivateRoute path=":resumeId" element={<WithResume />}>
          <Route path="profession" element={<Profession />} />
          <Route path="import/choose-method" element={<ChooseImportMethod />} />
          <Route path="import/name-company" element={<NameAndCompany />} />
          <Route path="import/profiles" element={<Profiles />} />
          <Route path="import/url" element={<ProfileUrl />} />
          <Route path="template-picker" element={<TemplatePicker />} />
          <Route path="review/work" element={<WorkExperienceReview />} />
          <Route path="review/education" element={<EducationReview />} />
          <Route path="review/about" element={<AboutReview />} />
          <Route path="review/contact" element={<ContactReview />} />
          <Route path="manual/work" element={<WorkExperienceManual />} />
          <Route path="manual/education" element={<EducationManual />} />
          <Route path="manual/about" element={<AboutManual />} />
          <Route path="manual/contact" element={<ContactManual />} />
          <Route
            path="account/no-preview"
            element={<Navigate to="/v2/setup/account" replace />}
          />
        </PrivateRoute>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
