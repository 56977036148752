import { getDefaultCustomizations } from '@sr/templates/src';
import {
  About,
  Customizations,
  Resume,
  Section,
  Slug,
  TemplateId,
} from '@sr/types';
import { ref, update } from 'firebase/database';
import { nanoid } from 'nanoid';
import { DeepNullable } from 'ts-essentials';
import empty from 'utils/empty-data';
import { db } from 'utils/firebase/database';
import { getValue } from 'utils/firebase/get-value';

export function getResumeRef(resumeId: string) {
  return ref(db, `resumes2/${resumeId}`);
}

export async function getResume(resumeId: string) {
  const ref = getResumeRef(resumeId);
  const resume = await getValue<Resume>(ref);
  return resume;
}

// TODO this incrorrectly allows undefined values for optional properties
export function updateResume(
  resumeId: string,
  updates: Partial<DeepNullable<Resume>>
) {
  const ref = getResumeRef(resumeId);
  return update(ref, updates);
}

export function updateAbout(resumeId: string, updates: Partial<About>) {
  const aboutRef = ref(db, `resumes2/${resumeId}/about`);
  return update(aboutRef, updates);
}

export async function updateActiveTemplate(
  resumeId: string,
  templateId: TemplateId
) {
  const resumeRef = getResumeRef(resumeId);
  const customizationsRef = ref(
    db,
    `resumes2/${resumeId}/style/customizations`
  );
  const customizations = await getValue<Customizations>(customizationsRef);
  return update(resumeRef, {
    style: {
      activeTemplateId: templateId,
      customizations: {
        [templateId]: getDefaultCustomizations(templateId),
        ...customizations,
      },
    },
  });
}

export async function createResume(userId: string, resume: Resume) {
  const slug: Slug = {
    id: resume.slug,
    ownerId: userId,
    resumeId: resume.id,
    visibility: resume.visibility,
  };
  const updates = {
    [`users2/${userId}/resumes/${resume.id}`]: resume.name,
    [`slugs2/${resume.slug}`]: slug,
    [`resumes2/${resume.id}`]: resume,
  };
  await update(ref(db), updates);
  return resume;
}

// Creates a new resume with blank/default values
export async function createBlankResume({
  userId,
  resumeId,
  addDefaultPhoto = false,
  templateId,
}: {
  userId: string;
  resumeId?: string;
  addDefaultPhoto?: boolean;
  templateId?: TemplateId;
}) {
  const emptyResume = empty.resume({
    id: resumeId || nanoid(),
    owner: userId,
    name: 'Untitled Resume',
    templateId: templateId,
    addDefaultPhoto,
  });
  const resume = await createResume(userId, emptyResume);
  return resume;
}

// Add a new section to a resume
export async function addDetailSection({
  resumeId,
  section,
}: {
  resumeId: string;
  section: Section;
}) {
  const resume = await getResume(resumeId);
  const updates: Partial<Resume> = {
    sections: { ...resume.sections, [section.sectionId]: section },
    sectionsOrder: [...(resume.sectionsOrder || []), section.sectionId],
  };
  await updateResume(resumeId, updates);
  return section;
}
