import { User } from '@sr/types';
import { ref, getDatabase, onValue, off } from 'firebase/database';
import type { DataSnapshot } from 'firebase/database';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useAuth } from './auth-context';
import { PageNotFound } from 'ui';
import 'utils/firebase/app';
import { DelayedFullscreenSpinner } from 'ui/DelayedFullscreenSpinner';

interface InternalUserState {
  user: User | null;
  status: Status;
  error: Error | null;
}
interface UserState {
  user: User;
}
type InitAction = { type: 'INIT' };
type UpdateAction = { type: 'UPDATE'; user: User | null };
type ErrorAction = { type: 'ERROR'; error: Error };
type Action = InitAction | UpdateAction | ErrorAction;
type Status = 'initial' | 'loading' | 'idle' | 'error';

const UserContext = React.createContext<UserState | undefined>(undefined);

function userReducer(
  state: InternalUserState,
  action: Action
): InternalUserState {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        status: 'loading',
      };
    case 'UPDATE':
      return {
        ...state,
        status: 'idle',
        user: action.user,
      };
    case 'ERROR':
      return {
        ...state,
        status: 'error',
        user: null,
        error: action.error,
      };
    default:
      throw new Error('Invalid action.');
  }
}

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<
    React.Reducer<InternalUserState, Action>
  >(userReducer, {
    status: 'initial',
    user: null,
    error: null,
  });

  const { userId } = useAuth();

  useEffect(() => {
    // Throw error early if there is no userId
    if (!userId) {
      dispatch({
        type: 'ERROR',
        error: new Error('No userId in URL params'),
      });
      return;
    }

    // Otherwise tell the reducer we're starting
    dispatch({ type: 'INIT' });

    function setValue(snapshot: DataSnapshot) {
      let user: User | null = null;
      if (snapshot.exists()) {
        user = snapshot.val();
      }
      dispatch({ type: 'UPDATE', user });
    }
    function setError(error: Error) {
      dispatch({ type: 'ERROR', error });
    }

    const db = getDatabase();
    const query = ref(db, `users2/${userId}`);

    onValue(query, setValue, setError);

    return () => {
      off(query, 'value', setValue);
    };
  }, [userId]);

  const { status, user, error } = state;

  const memoUserState: UserState | null = useMemo(() => {
    return user === null ? null : { user };
  }, [user]);

  if (status === 'initial' || status === 'loading') {
    return <DelayedFullscreenSpinner />;
  }

  if (status === 'error') {
    // This will be picked up by the Sentry ErrorBoundary
    throw error;
  }

  if (memoUserState === null) {
    return <PageNotFound />;
  }

  if (status === 'idle') {
    return (
      <UserContext.Provider value={memoUserState}>
        {children}
      </UserContext.Provider>
    );
  }

  throw new Error(`Unhandled status: ${status}`);
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
