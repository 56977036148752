import React, { useMemo } from 'react';
import { Box, Label } from 'ui';
import { ScssObject, convertScss } from 'ui/scss';

interface TextareaCustomProps {
  label?: string;
  containerScss?: ScssObject;
  labelScss?: ScssObject;
  textareaScss?: ScssObject;
}

export interface TextareaProps
  extends React.ComponentPropsWithRef<'textarea'>,
    TextareaCustomProps {}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, name, containerScss, labelScss, textareaScss, ...props }, ref) => {
    const styles = useMemo(
      () => convertScss({ ...textareaStyles, ...textareaScss }),
      [textareaScss]
    );
    return (
      <Box scss={containerScss}>
        {label && (
          <Label htmlFor={name} scss={labelScss}>
            {label}
          </Label>
        )}
        <textarea
          ref={ref}
          name={name}
          id={label ? name : undefined}
          className={styles}
          {...props}
        />
      </Box>
    );
  }
);

const textareaStyles: ScssObject = {
  appearance: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  resize: 'vertical',
  bg: 'white',
  borderRadius: 'small',
  border: 0,
  width: '100%',
  py: 12,
  px: 16,
};
