import { css as emotionCss, CSSObject, cx } from '@emotion/css';
import { css as tuiCss, Theme, ThemeUIStyleObject } from 'ui/sx/copied/css';
import { theme } from 'ui/theme';

// Scss is a thin wrapper over Emotion, that uses the css function from
// theme-ui for themed values and css property aliases (eg: mx)

type ScssObject = ThemeUIStyleObject;
type ScssProp = { scss?: ScssObject };

export type { ScssObject, ScssProp, Theme };
export { cx, emotionCss };

// Applies values from the theme like colors and fonts
export function applyTheme(styles: ScssObject | undefined): CSSObject {
  return tuiCss(styles)(theme);
}

// Converts an scss/ThemeUIStyleObject to a class name with Emotion
export function convertScss(styles: ScssObject | undefined): string {
  return emotionCss(applyTheme(styles));
}

// Converts scss/ThemeUIStyleObject objects to class names
// Meant to be used like: className={scssClasses({ color: 'primary' })}
export function scssClasses(...styles: ScssObject[]): string {
  if (styles.length === 0) {
    return '';
  }
  if (styles.length === 1) {
    return cx(convertScss(styles[0]));
  } else {
    return cx.apply(
      null,
      styles.map((s) => convertScss(s))
    );
  }
}
