import React from 'react';
import { useNavigate } from 'react-router-dom-6';
import { PageLogo } from './PageLogo';
import { Button } from 'ui/elements/Button';
import { ArrowLeftIcon } from 'ui/icons';
import { convertScss, ScssObject, ScssProp } from 'ui/scss';
import { spacing } from 'ui/theme';

interface PageProps {
  children: React.ReactNode;
  hideBackButton?: boolean;
  hideLogo?: boolean;
}

export function Page({ children, hideBackButton, hideLogo }: PageProps) {
  return (
    <div className={pageStyles}>
      {children}
      {!hideBackButton && <BackButton />}
      {!hideLogo && <PageLogo />}
    </div>
  );
}

const pageStyles = convertScss({
  position: 'relative',
  width: '100vw',
  height: '100vh',
  // Hack to fix mobile Safari 100vh bug
  // https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit
  maxHeight: '-webkit-fill-available',
  label: 'Page',
});

export interface PageContentProps
  extends React.ComponentPropsWithRef<'div'>,
    ScssProp {}

export function PageContent({ scss, ...props }: PageContentProps) {
  return (
    <div
      className={convertScss({ ...pageContentStyles, ...scss })}
      {...props}
    />
  );
}

const pageContentStyles: ScssObject = {
  height: '100%',
  width: '100%',
  px: spacing.pagePaddingX,
  py: spacing.pagePaddingY,
  overflowY: 'auto',
  label: 'PageContent',
};

export const BackButton = React.memo(function BackButton() {
  const navigate = useNavigate();
  return (
    <div className={backButtonStyles}>
      <Button variant="icon" onClick={() => navigate(-1)}>
        <ArrowLeftIcon />
      </Button>
    </div>
  );
});

const backButtonStyles = convertScss({
  display: ['none', 'block'],
  position: 'fixed',
  top: spacing.pagePaddingX,
  left: spacing.pagePaddingX,
  label: 'BackButton',
});
