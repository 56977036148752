import * as Sentry from '@sentry/browser';
import type { User } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useMemo, useReducer } from 'react';
import { DelayedFullscreenSpinner } from 'ui/DelayedFullscreenSpinner';
import { auth } from 'utils/firebase/auth';
import 'utils/firebase/app';

type InternalAuthState = Pick<AuthState, 'userId'> & {
  status: Status;
  error: Error | null;
};
type AuthState =
  | { userId: string; isAuthed: true }
  | { userId: null; isAuthed: false };
type InitAction = { type: 'INIT' };
type UpdateAction = { type: 'UPDATE'; userId: string | null };
type ErrorAction = { type: 'ERROR'; error: Error };
type Action = InitAction | UpdateAction | ErrorAction;
type Status = 'initial' | 'loading' | 'idle' | 'error';

const AuthContext = React.createContext<AuthState | undefined>(undefined);

function authReducer(
  state: InternalAuthState,
  action: Action
): InternalAuthState {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        status: 'loading',
      };
    case 'UPDATE':
      return {
        ...state,
        status: 'idle',
        userId: action.userId,
      };
    case 'ERROR':
      return {
        ...state,
        status: 'error',
        userId: null,
        error: action.error,
      };
    default:
      throw new Error('Invalid action.');
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<
    React.Reducer<InternalAuthState, Action>
  >(authReducer, {
    status: 'initial',
    userId: null,
    error: null,
  });

  useEffect(() => {
    function setData(user: User | null) {
      // Add the user's id to Sentry for easier debugging
      if (user && user.uid) {
        Sentry.setUser({
          id: user.uid,
          ip_address: '{{auto}}',
        });
      }
      dispatch({ type: 'UPDATE', userId: user ? user.uid : null });
    }
    function setError(error: Error) {
      dispatch({ type: 'ERROR', error });
    }

    // Check faster but potentially not set auth.currentUser
    if (auth.currentUser) {
      setData(auth.currentUser);
    } else {
      dispatch({ type: 'INIT' });
    }

    const listener = onAuthStateChanged(auth, setData, setError);
    return () => {
      listener();
    };
  }, []);

  const { status, userId, error } = state;

  const value: AuthState = useMemo(() => {
    // This conditional is so we can have the stricly typed AuthState
    if (userId && status === 'idle') {
      return { userId, isAuthed: true };
    } else {
      return { userId: null, isAuthed: false };
    }
  }, [status, userId]);

  if (status === 'initial' || status === 'loading') {
    return <DelayedFullscreenSpinner />;
  }

  if (status === 'error') {
    throw error;
  }

  if (status === 'idle') {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }

  throw new Error(`Unhandled status: ${status}`);
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}
