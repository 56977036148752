import React, { useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import useIsMounted from 'hooks/use-is-mounted';
import {
  Box,
  Button,
  Form,
  Grid,
  Heading,
  NavbarPage,
  Page,
  PageContent,
  ProgressSteps,
  SplitContent,
  VerticalStackContainer,
} from 'ui';
import { ArrowRightIcon, PlusIcon } from 'ui/icons';
import { ScssObject } from 'ui/scss';
import { animations } from 'ui/theme';
import { hotkeysConfigs } from 'utils/use-hotkeys';

export function OutlinePage({
  activeStep,
  animate,
  children,
}: {
  activeStep: string;
  animate?: boolean;
  children: React.ReactNode;
}) {
  const steps = useRef(['Work', 'Education', 'About', 'Contact']);
  return (
    <NavbarPage
      nav={<ProgressSteps steps={steps.current} activeStep={activeStep} />}
    >
      <PageContent scss={animate ? animations.fadeIn : {}}>
        <VerticalStackContainer scss={{ maxWidth: 'smallContainer' }}>
          {children}
        </VerticalStackContainer>
      </PageContent>
    </NavbarPage>
  );
}

export function SplitOutlinePage({
  activeStep,
  animate,
  left,
  right,
}: {
  activeStep: string;
  animate?: boolean;
  left: React.ReactNode;
  right: React.ReactNode;
}) {
  const steps = useRef(['Work', 'Education', 'About', 'Contact']);
  return (
    <NavbarPage
      nav={<ProgressSteps steps={steps.current} activeStep={activeStep} />}
    >
      <Box
        scss={{
          // Need this to prevent main content from squishing top nav bar
          width: '100%',
          height: '100%',
          position: 'relative',
          overflowY: 'auto',
          animation: animate ? animations.fadeIn : null,
        }}
      >
        <PageContent>
          <SplitContent left={left} right={right} />
        </PageContent>
      </Box>
    </NavbarPage>
  );
}

export function SplitPage({
  left,
  right,
}: {
  left: React.ReactNode;
  right: React.ReactNode;
}) {
  return (
    <Page>
      <PageContent>
        <SplitContent left={left} right={right} />
      </PageContent>
    </Page>
  );
}

export function SimplePage({
  animate,
  children,
  hideBackButton,
}: {
  animate?: boolean;
  children: React.ReactNode;
  hideBackButton?: boolean;
}) {
  return (
    <Page hideBackButton={hideBackButton}>
      <PageContent scss={animate ? animations.fadeIn : {}}>
        <VerticalStackContainer scss={{ maxWidth: 'smallContainer' }}>
          {children}
        </VerticalStackContainer>
      </PageContent>
    </Page>
  );
}

export function Header({
  align = 'center',
  children,
  subtext,
  icon,
  scss,
}: {
  align?: 'left' | 'center';
  children: React.ReactNode;
  subtext?: string;
  icon: React.ReactNode;
  scss?: ScssObject;
}) {
  const isLeft = align === 'left';
  return (
    <Box
      scss={{
        width: '100%',
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: isLeft ? 'flex-start' : 'center',
        alignSelf: isLeft ? 'flex-start' : 'center',
        mb: 48,
        ...scss,
      }}
    >
      <Box scss={{ color: 'blue50', mb: 8 }}>{icon}</Box>
      <Heading scss={{ textAlign: isLeft ? 'left' : 'center' }}>
        {children}
      </Heading>
      {subtext && (
        <Box
          scss={{
            color: 'black50',
            textAlign: isLeft ? 'left' : 'center',
            mt: 8,
          }}
        >
          {subtext}
        </Box>
      )}
    </Box>
  );
}

type InputGridProps = {
  children: React.ReactNode;
  columns?: number;
  scss?: ScssObject;
};
export function InputGrid(props: InputGridProps) {
  const columns = props.columns ?? 2;
  return (
    <Grid
      scss={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: [12, 24],
        width: '100%',
        ...props.scss,
      }}
    >
      {props.children}
    </Grid>
  );
}

type GridFormProps = {
  align?: 'left' | 'center';
  children: React.ReactNode;
  columns?: number;
  error?: string | null;
  nextButtonText?: string;
  scss?: ScssObject;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => Promise<void>;
};
export function GridForm(props: GridFormProps) {
  const {
    align = 'center',
    children,
    columns,
    error,
    nextButtonText,
    onSubmit,
    scss,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  function handleSubmit(e?: React.FormEvent<HTMLFormElement>): void {
    if (e) e.preventDefault();
    // Prevent resubmission while form is already loading
    if (!isLoading) {
      setIsLoading(true);
      onSubmit(e).finally(() => {
        isMounted() && setIsLoading(false);
      });
    }
  }

  // Submit form when enter pressed while not focused on form or button
  useHotkeys('enter', () => handleSubmit(), hotkeysConfigs.default);

  // Submit form when cmd+enter is pressed when a textarea is focused
  useHotkeys(
    'command+enter',
    () => handleSubmit(),
    hotkeysConfigs.textareaOnly
  );

  const errorComponent = useMemo(
    () =>
      error && <Box scss={{ color: 'error', textAlign: align }}>{error}</Box>,
    [align, error]
  );

  const buttonStyles = useMemo(
    () => ({
      gridColumn: '1 / -1',
      justifySelf: align === 'left' ? 'flex-start' : 'center',
      mt: 24,
    }),
    [align]
  );

  return (
    <Form onSubmit={handleSubmit} scss={{ width: '100%', ...scss }}>
      <InputGrid columns={columns}>
        {children}
        {errorComponent}
        <NextButton
          type="submit"
          hotkey="enter"
          scss={buttonStyles}
          loading={isLoading}
          children={nextButtonText}
        />
      </InputGrid>
    </Form>
  );
}

interface NextButtonProps extends React.ComponentProps<typeof Button> {
  loading?: boolean;
}

export function NextButton({
  children,
  rightIcon,
  loading,
  scss,
  ...props
}: NextButtonProps) {
  return (
    <Button
      scss={{ width: 275, ...scss }}
      rightIcon={rightIcon || <ArrowRightIcon />}
      loading={loading}
      data-testid="next-button"
      {...props}
    >
      {children || 'Next'}
    </Button>
  );
}

export function AddButton(props: React.ComponentProps<typeof Button>) {
  return (
    <Button
      type="button"
      variant="outline"
      leftIcon={<PlusIcon />}
      scss={{ gridColumn: '1 / -1' }}
      {...props}
    />
  );
}
